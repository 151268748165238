export function getThemeColor(iconColor: string): string {
  if (iconColor === "blue") {
    return "#2e86de";
  }

  if (iconColor === "lightblue") {
    return "#54a0ff";
  }

  if (iconColor === "purple") {
    return "#c54dff";
  }

  if (iconColor === "yellow") {
    return "#feca57";
  }

  if (iconColor === "green") {
    return "#00b894";
  }

  if (iconColor === "red") {
    return "#e74c3c";
  }

  return "#fff";
}
