import * as React from "react";
import useWebSpeech from "./utils/useSpeech";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faPaperPlane,
  faUserNurse,
  faChevronUp,
  faChevronDown,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "./IconButton";
import useAsyncEffect from "./utils/useAsyncEffect";
import callFirebaseFunction from "./utils/callFirebaseFunction";
import { FullPageLoadingSpinner } from "./FullPageLoadingSpinner";
import { Patient } from "./PatientList";
import { useMatches } from "react-router-dom";
import { PatientInfo } from "./PatientInfo";
import PatientIcon from "./PatientIcon";
import { IconWithBackground } from "./IconWithBackground";
import LoadingSpinner from "./LoadingSpinner";
import { animated } from "@react-spring/web";
import usePopIn from "./utils/usePopIn";
import { Button } from "./Button";
import ContentBox from "./ContentBox";
import { getThemeColor } from "./utils/getThemeColor";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Banner from "./Banner";
import { getYearMonthDay } from "./utils/getYearMonthDay";
import { getThemeColorDeemphasized } from "./utils/getThemeColorDeemphasized";
import getPatientColor from "./utils/getPatientColor";

const CHILD_AGE_DAY_CUTOFF = 3800;

function ChatMessageBox({
  message,
  isFromUser,
  patientData,
  style,
}: {
  message: string;
  isFromUser: boolean;
  patientData: null | Patient;
  style: any;
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "end",
        flexDirection: isFromUser ? "row-reverse" : "row",
      }}
    >
      <div
        style={{
          flexShrink: 0,
          borderRadius: "50%",
          overflow: "hidden",
          transform: "scale(0.7)",
          transformOrigin: isFromUser ? "right bottom 0px" : "left bottom 0px",
        }}
      >
        {isFromUser ? (
          <IconWithBackground icon={faUserNurse} color="lightblue" />
        ) : (
          <PatientIcon patientData={patientData} />
        )}
      </div>
      <ContentBox
        style={{
          padding: 16,
          display: "flex",
          gap: 8,
          alignItems: "center",
          background: isFromUser
            ? "#fff"
            : getThemeColorDeemphasized(getPatientColor(patientData)),
          ...style,
        }}
      >
        {message.replace(/^[a-zA-Z0-9]+:\s+/, "")}
      </ContentBox>
    </div>
  );
}

function SendMessageBox({
  setIsSendingMessage,
  setMessages,
  isSendingMessage,
  id,
}: {
  setIsSendingMessage: React.Dispatch<React.SetStateAction<boolean>>;
  setMessages: React.Dispatch<React.SetStateAction<string[]>>;
  isSendingMessage: boolean;
  id: string | null | undefined;
}) {
  const [message, setMessage] = React.useState("");

  const sendMessage = React.useCallback(
    async (message: string) => {
      if (message.trim().length === 0) {
        // Nothing to send
        return;
      }

      if (isSendingMessage) {
        // Don't allow sending while already sending.
        return;
      }

      setMessages((old: Array<string>) => [...old, message]);
      setMessage("");
      setIsSendingMessage(true);

      try {
        const resp = await callFirebaseFunction<{ response: string }>("chat", {
          id,
          message,
        });

        setIsSendingMessage(false);
        setMessages((old: Array<string>) => [...old, resp.response]);
      } catch (e) {
        setIsSendingMessage(false);
        setMessages((old) => old.slice(0, old.length - 1));
        alert("There was an error sending the message, please try again.");
      }
    },
    [setMessages, setIsSendingMessage, setMessage]
  );

  const onNewResult = React.useCallback(
    (words: string) => setMessage(words),
    [setMessage]
  );
  const onFinalResult = React.useCallback(
    (words: string) => sendMessage(words),
    [sendMessage]
  );

  const { isRecording, start, stop, isSupported } = useWebSpeech({
    onNewResult,
    onFinalResult,
  });

  return (
    <ContentBox
      style={{
        display: "flex",
        gap: 8,
        padding: 8,
        margin: 20,
        alignItems: "center",
        pointerEvents: id == null ? "none" : "initial",
      }}
    >
      <textarea
        style={{
          minWidth: 0,
          flexGrow: 1,
          resize: "none",
          border: "none",
          background: "none",
          outline: "none",
          fontFamily: '"Montserrat", sans-serif',
          color: "inherit",
        }}
        placeholder="Send a message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => {
          if (
            e.code === "Enter" &&
            !e.altKey &&
            !e.shiftKey &&
            !e.ctrlKey &&
            !isRecording
          ) {
            e.stopPropagation();
            e.preventDefault();

            sendMessage(message);
          }
        }}
      />
      <span
        data-tooltip-id="tooltip"
        data-tooltip-content={
          !isSupported
            ? "Speech to text is not available on your browser. Please try Chrome or Safari if you wish to use this feature."
            : "Talk to the patient naturally using speech to text."
        }
      >
        <IconButton
          disabled={!isSupported}
          onClick={() => {
            if (isRecording) {
              stop();
            } else {
              start();
            }
          }}
        >
          {isRecording ? (
            <div
              style={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                border: "1px solid red",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                animation: "blinker 2s linear infinite",
              }}
            >
              <div
                style={{
                  width: 7,
                  height: 7,
                  borderRadius: "50%",
                  background: "red",
                }}
              ></div>
            </div>
          ) : (
            <FontAwesomeIcon icon={faMicrophone} />
          )}
        </IconButton>
      </span>
      <span
        data-tooltip-id="tooltip"
        data-tooltip-content={
          isRecording
            ? "While recording, your messages will automatically send when we detect you've finished speaking."
            : ""
        }
      >
        <IconButton
          disabled={isSendingMessage || isRecording}
          onClick={() => {
            sendMessage(message);
          }}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </IconButton>
      </span>
      <Tooltip
        id="tooltip"
        globalCloseEvents={{
          clickOutsideAnchor: true,
        }}
        style={{
          maxWidth: "calc(100% - 40px)",
          background: "#fafafa",
          color: "inherit",
          boxShadow: "0 0 6px -2px #ccc",
          borderRadius: 12,
        }}
      />
    </ContentBox>
  );
}

function getHelpText(patientData: Patient | null): string {
  if (patientData?.type === "pediatrics") {
    const readableAge = getYearMonthDay(patientData.ageDays);
    return (
      `You are speaking with ${patientData.name}'s parent, who is here for ` +
      `${patientData.name}'s ${readableAge} wellness visit.`
    );
  } else if (
    patientData?.type === "diagnosis" &&
    patientData?.ageDays < CHILD_AGE_DAY_CUTOFF
  ) {
    return (
      `You are speaking with ${patientData.name}'s parent, who is seeking ` +
      `medical care for their child. Ask them what symptoms ` +
      `${patientData.name} is having and then spend the ` +
      `next 5 to 10 minutes obtaining a focused and relevant history from ` +
      `${patientData.name}'s parent. You can click "Show Patient Info" to ` +
      `reveal the condition once you are finished.`
    );
  } else if (patientData?.type === "diagnosis") {
    return (
      `You are speaking with ${patientData.name} who is seeking ` +
      `medical care. Ask them what symptoms they have and then spend the ` +
      `next 5 to 10 minutes obtaining a focused and relevant history from ` +
      `${patientData.name}. You can click "Show Patient Info" to reveal the ` +
      `condition once you are finished.`
    );
  } else if (
    patientData?.type === "counselling" &&
    patientData?.ageDays < CHILD_AGE_DAY_CUTOFF
  ) {
    return (
      `You are speaking with ${patientData.name}'s parent, who is here to talk about ${patientData.name}'s ` +
      `${patientData.condition}. Spend the next 5 to 10 minutes counselling ` +
      `and developing a management plan on ${patientData.name}'s ` +
      `${patientData.condition}. You can click "Show Patient Info" if needed.`
    );
  } else if (patientData?.type === "counselling") {
    return (
      `You are speaking with ${patientData.name} who has ` +
      `${patientData.condition}. Spend the next 5 to 10 minutes counselling ` +
      `and developing a management plan on ${patientData.name}'s ` +
      `${patientData.condition}. You can click "Show Patient Info" if needed.`
    );
  } else if (patientData?.type === "difficult") {
    return (
      `You are speaking with ${patientData.name}, who is here to hear ` +
      `news on their diagnosis. You should make up a scenario, such as ` +
      `delivering the result of a blood test, and run through it with the ` +
      `patient.`
    );
  }

  return "";
}

function HelpBox(props: { patientData: Patient | null }) {
  return (
    <Banner type="info">
      {getHelpText(props.patientData)} You can type in the message box, or click
      the microphone icon to speak to them using your voice.
    </Banner>
  );
}

const AnimatedChatMessageBox = animated(ChatMessageBox);

export default function PatientPage(props: {
  messages?: Array<string>;
  isSendingMessage?: boolean;
  hideHelp?: boolean;
  hidePatientInfo?: boolean;
}) {
  const matches = useMatches();

  const id = matches[0].params.id;

  const [patientData, setPatientData] = React.useState<null | Patient>(null);
  const [messages, setMessages] = React.useState<Array<string>>(
    props.messages ?? []
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const [isRatingSession, setIsRatingSession] = React.useState(false);
  const [sessionRating, setSessionRating] = React.useState("");
  const scrollRef = React.useRef<HTMLDivElement | null>(null);
  const [isSendingMessage, setIsSendingMessage] = React.useState(false);
  const [showPatientInfo, setShowPatientInfo] = React.useState(false);

  const styles = usePopIn({ amount: messages.length, translateY: 0 });

  React.useEffect(() => {
    if (scrollRef.current == null) {
      return;
    }
    scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
  }, [messages, isSendingMessage]);

  React.useEffect(() => {
    setMessages(props.messages ?? []);
  }, [props.messages]);

  React.useEffect(() => {
    setIsSendingMessage(props.isSendingMessage ?? false);
  }, [props.isSendingMessage]);

  useAsyncEffect(
    async () => {
      if (id == null && props.messages != null) {
        return {
          id: "test",
          createdAt: { _seconds: Date.now() - 86400 },
          name: "Bob",
          genderIdx: 0,
          condition: "Chickenpox",
          ageDays: 10951,
          type: "counselling",
          messages: props.messages.map((msg) => ({
            content: msg,
            role: "ignored",
          })),
        };
      }
      return await callFirebaseFunction<Patient>("getPatient", { id });
    },
    (result) => {
      const newMsgs = (result.messages ?? []).map((x) => x.content);

      setPatientData(result);
      setMessages(newMsgs);
      setIsLoading(false);
    }
  );

  if (id == null && props.messages == null) {
    return "Error loading chat. Is the ID invalid?";
  }

  if (isLoading) {
    return <FullPageLoadingSpinner />;
  }

  const messageElements = messages.map((msg, idx) => {
    return (
      <AnimatedChatMessageBox
        key={idx}
        message={msg}
        isFromUser={idx % 2 === 0}
        patientData={patientData}
        style={styles[idx]}
      />
    );
  });

  const isPendingUserMessage = messages.length % 2 === 0;

  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        height: "100%",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <dialog
          open={sessionRating !== ""}
          style={{
            border: "none",
            borderRadius: 12,
            boxShadow: "0 0 6px -2px rgba(0, 0, 0, 0.4)",
            background: "white",
            zIndex: 999,
            maxWidth: "calc(100vw - 40px)",
            marginTop: 16,
            maxHeight: "calc(100% - 40px)",
            overflowY: "auto",
          }}
        >
          <h2>Rating</h2>
          <pre
            style={{
              fontFamily: "inherit",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {sessionRating}
          </pre>
          <div style={{ fontSize: 12, fontWeight: 300, marginTop: 40 }}>
            Disclaimer: This is an AI generated rating and summary, it may
            provide incorrect information. It does not know the scoring rules of
            any real examinations and does not represent a real score you may
            receive. It is not recommended to use this rating or summary to
            learn from.
          </div>
          <div style={{ marginTop: 20 }}>
            <Button
              onClick={() => {
                setSessionRating("");
              }}
            >
              Close Rating
            </Button>
          </div>
        </dialog>
        <div
          style={{
            flexGrow: 1,
            padding: "20px 20px 0 20px",
            display: "flex",
            flexDirection: "column",
            gap: 12,
            overflowY: "auto",
            marginTop: props.hidePatientInfo ? 0 : 78,
          }}
          ref={scrollRef}
        >
          {!props.hideHelp && <HelpBox patientData={patientData} />}
          {messageElements}
          {isSendingMessage && (
            <div
              style={{
                display: "flex",
                gap: 20,
                padding: 20,
                justifyContent: isPendingUserMessage ? "end" : "start",
              }}
            >
              <LoadingSpinner
                size={20}
                delay={0}
                color={getThemeColor(
                  isPendingUserMessage ? "blue" : getPatientColor(patientData)
                )}
              />
              <LoadingSpinner
                size={20}
                delay={25}
                color={getThemeColor(
                  isPendingUserMessage ? "blue" : getPatientColor(patientData)
                )}
              />
              <LoadingSpinner
                size={20}
                delay={50}
                color={getThemeColor(
                  isPendingUserMessage ? "blue" : getPatientColor(patientData)
                )}
              />
            </div>
          )}
        </div>

        <div style={{ flexShrink: 0 }}>
          <SendMessageBox
            setMessages={setMessages}
            id={id}
            isSendingMessage={isSendingMessage}
            setIsSendingMessage={setIsSendingMessage}
          />
        </div>
      </div>
      {!props.hidePatientInfo && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            display: "flex",
            padding: 20,
            gap: 20,
            background: "white",
            boxShadow: "0 0 6px -2px rgb(214, 235, 253)",
            flexDirection: "column",
            flexShrink: 0,
            transform: showPatientInfo
              ? "translateY(0px)"
              : "translateY(calc(-100% + 78px))",
            transition: "transform 250ms ease-out",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 20,
              justifyContent: "space-around",
              alignItems: "start",
              flexWrap: "wrap",
              maxWidth: 480,
              width: "100%",
            }}
          >
            <PatientInfo patientData={patientData} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
            }}
          >
            <Button
              color={getThemeColor("yellow")}
              textColor="inherit"
              onClick={() => {
                setShowPatientInfo((old) => !old);
              }}
              icon={showPatientInfo ? faChevronUp : faChevronDown}
            >
              {showPatientInfo ? "Hide" : "Show"} Patient Info
            </Button>
            <Button
              color={getThemeColor("yellow")}
              textColor="inherit"
              onClick={async () => {
                setIsRatingSession(true);
                const { rating } = await callFirebaseFunction<{
                  rating: string;
                }>("score", { id });
                setIsRatingSession(false);
                setSessionRating(rating);
              }}
              loading={isRatingSession}
              disabled={isRatingSession || isLoading || isSendingMessage}
              icon={faPencil}
            >
              Rate My Session
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
