import * as React from "react";
import { getThemeColor } from "./utils/getThemeColor";

export function IconButton({
  children,
  onClick,
  disabled,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={{
        color: disabled ? "#666" : getThemeColor("blue"),
        width: 36,
        height: 36,
        padding: "8px",
        borderRadius: 8,
        cursor: "pointer",
        background: "#f9f9f9",
        border: "1px solid #f3f3f3",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 2px 8px -3px #eee",
      }}
    >
      {children}
    </button>
  );
}
