import * as React from "react";
import Chat from "./PatientPage";
import CreatePatient from "./CreatePatient";
import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
  useLocation,
} from "react-router-dom";
import { LoginPage } from "./LoginPage";
import { auth } from "./utils/firebase";
import { onAuthStateChanged } from "@firebase/auth";
import { LandingPage } from "./LandingPage";
import { FullPageLoadingSpinner } from "./FullPageLoadingSpinner";
import { PatientList } from "./PatientList";
import Header from "./Header";
import { getThemeColor } from "./utils/getThemeColor";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import TermsPage from "./TermsPage";
import SettingsPage from "./SettingsPage";
import {
  faGear,
  faPlusSquare,
  faRectangleList,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "./Button";
import { log, identify, resetIdentify } from "./utils/log";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RootComponent>
        <RequireLogin>
          <CreatePatient />
        </RequireLogin>
      </RootComponent>
    ),
  },
  {
    path: "/features",
    element: (
      <RootComponent>
        <LandingPage section="features" />
      </RootComponent>
    ),
  },
  {
    path: "/pricing",
    element: (
      <RootComponent>
        <LandingPage section="pricing" />
      </RootComponent>
    ),
  },
  {
    path: "/contact",
    element: (
      <RootComponent>
        <LandingPage section="contact" />
      </RootComponent>
    ),
  },
  {
    path: "/create",
    element: (
      <RootComponent>
        <RequireLogin>
          <CreatePatient />
        </RequireLogin>
      </RootComponent>
    ),
  },
  {
    path: "/landing",
    element: (
      <RootComponent>
        <LandingPage />
      </RootComponent>
    ),
  },
  {
    path: "/chat/:id",
    element: (
      <RootComponent>
        <RequireLogin>
          <Chat />
        </RequireLogin>
      </RootComponent>
    ),
  },
  {
    path: "/patient/:id",
    element: (
      <RootComponent>
        <RequireLogin>
          <Chat />
        </RequireLogin>
      </RootComponent>
    ),
  },
  {
    path: "/signin",
    element: (
      <RootComponent>
        <LoginPage isSignUp={false} />
      </RootComponent>
    ),
  },
  {
    path: "/signup",
    element: (
      <RootComponent>
        <LoginPage isSignUp={true} />
      </RootComponent>
    ),
  },
  {
    path: "/history",
    element: (
      <RootComponent>
        <RequireLogin>
          <PatientList />
        </RequireLogin>
      </RootComponent>
    ),
  },
  {
    path: "/privacy",
    element: (
      <RootComponent>
        <PrivacyPolicyPage />
      </RootComponent>
    ),
  },
  {
    path: "/terms",
    element: (
      <RootComponent>
        <TermsPage />
      </RootComponent>
    ),
  },
  {
    path: "/settings",
    element: (
      <RootComponent>
        <RequireLogin>
          <SettingsPage />
        </RequireLogin>
      </RootComponent>
    ),
  },
]);

// All routes should wrap with this
function RootComponent(props: { children: React.ReactNode }) {
  const location = useLocation();
  React.useEffect(() => {
    log({ event: "pageview", target: location.pathname });
  }, [location]);

  return (
    <>
      {props.children}
      <ScrollRestoration />
    </>
  );
}

function RequireLogin(props: { children: React.ReactNode }) {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = React.useState<null | boolean>(null);

  React.useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(user != null);
      if (user != null) {
        identify({
          uid: user.uid,
          email: user.email,
          name: user.displayName,
        });
      } else {
        resetIdentify();
      }
    });
  }, []);

  if (isLoggedIn == null) {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <Header background={getThemeColor("lightblue")} />
        <FullPageLoadingSpinner />
      </div>
    );
  }

  if (isLoggedIn === false) {
    return <LandingPage />;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Header
        background={getThemeColor("lightblue")}
        rightContent={
          <Button
            color={
              location.pathname === "/settings" ? "#ffffff44" : "#ffffff1f"
            }
            href="/settings"
            icon={faGear}
          />
        }
        middleContent={
          <div style={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Button
              color={
                location.pathname === "/" || location.pathname === "/create"
                  ? "#ffffff44"
                  : "#ffffff1f"
              }
              href="/"
              borderRadius="8px 2px 2px 8px"
              icon={faPlusSquare}
            >
              <span
                style={{
                  fontWeight:
                    location.pathname === "/" || location.pathname === "/create"
                      ? 600
                      : 400,
                }}
              >
                Create Patient
              </span>
            </Button>
            <Button
              color={
                location.pathname === "/history" ? "#ffffff44" : "#ffffff1f"
              }
              href="/history"
              borderRadius="2px 8px 8px 2px"
              icon={faRectangleList}
            >
              <span
                style={{
                  fontWeight: location.pathname === "/history" ? 600 : 400,
                }}
              >
                Patient History
              </span>
            </Button>
          </div>
        }
      />
      {props.children}
    </div>
  );
}

export default function AppRoot() {
  return <RouterProvider router={router} />;
}
