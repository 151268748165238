import * as React from "react";

export function HorizontalDivider() {
  return (
    <div
      style={{
        height: 1,
        width: "100%",
        background: "rgba(0, 0, 0, 0.1)",
      }}
    />
  );
}
