import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getThemeColor } from "./utils/getThemeColor";
import React from "react";
import { getThemeColorDeemphasized } from "./utils/getThemeColorDeemphasized";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

function Wrapper({
  color,
  children,
  size,
}: {
  color: string;
  children: React.ReactNode;
  size?: number;
}) {
  return (
    <div
      style={{
        width: size ?? 48,
        height: size ?? 48,
        borderRadius: 12,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: color,
      }}
    >
      {children}
    </div>
  );
}

export function IconWithBackground({
  color,
  icon,
  size,
  iconSize,
}: {
  color: string;
  icon: any;
  size?: number;
  iconSize?: SizeProp;
}) {
  return (
    <Wrapper color={getThemeColorDeemphasized(color)} size={size}>
      <FontAwesomeIcon
        icon={icon}
        size={iconSize ?? "2x"}
        color={getThemeColor(color)}
      />
    </Wrapper>
  );
}
