import React, { useEffect } from "react";

function stopListeningToVolume() {
  if (currentStream != null) {
    currentStream.getTracks().forEach((t) => {
      t.stop();
      currentStream.removeTrack(t);
    });

    scriptNode.onaudioprocess = null;
    scriptNode?.disconnect();
  }
}

let scriptNode: ScriptProcessorNode;
let currentStream: MediaStream;

function getMicrophoneVolume(callback) {
  // Check if the Web Audio API is available in the browser
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    // Create an audio context
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();

    // Create a media stream to access the microphone
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        currentStream = stream;

        // Create an audio source from the media stream
        const microphone = audioContext.createMediaStreamSource(stream);

        // Create a script processor node to analyze the audio data
        scriptNode = audioContext.createScriptProcessor(4096, 1, 1);

        // Connect the microphone to the script processor
        microphone.connect(scriptNode);

        // Connect the script processor to the audio context's destination (output)
        scriptNode.connect(audioContext.destination);

        // Define a function to process the audio data
        scriptNode.onaudioprocess = function (event) {
          // Get the input audio data
          const inputBuffer = event.inputBuffer.getChannelData(0);

          // Calculate the volume level (RMS) of the audio data
          let sum = 0;
          for (let i = 0; i < inputBuffer.length; i++) {
            sum += inputBuffer[i] * inputBuffer[i];
          }
          const rms = Math.sqrt(sum / inputBuffer.length);

          // Call the callback with the current volume level
          callback(rms);
        };
      })
      .catch((error) => {
        console.error("Error accessing the microphone:", error);
        callback(0); // Call the callback with volume level 0 in case of an error
      });
  } else {
    console.error(
      "Web Audio API is not supported in this browser.",
      navigator.mediaDevices,
      navigator.mediaCapabilities
    );
    callback(0); // Call the callback with volume level 0 if the Web Audio API is not supported
  }
}

let timeoutId;

let microphoneVolumeLevelAvg = 0;
const recognition =
  window.webkitSpeechRecognition != null
    ? new window.webkitSpeechRecognition()
    : null;

if (recognition != null) {
  recognition.continuous = true;
  recognition.interimResults = true;
  recognition.lang = "en-US";
}

// For some reason, Chrome on android gives wonky results. This function detects
// and filters those out
function resultsContainPriorResults(results: Array<string>) {
  for (let x = 1; x < results.length; x++) {
    if (!results[x].startsWith(results[x - 1])) {
      return false;
    }
  }
  return true;
}

export default function useWebSpeech({
  onNewResult,
  onFinalResult,
}: {
  onNewResult?: (words: string) => void;
  onFinalResult?: (words: string) => void;
}) {
  const [isRecording, setIsRecording] = React.useState(false);
  const isRecordingRef = React.useRef(false);

  useEffect(() => {
    if (recognition == null) {
      return;
    }

    const debouncedSendFinalResult = (transcript: string) => {
      clearTimeout(timeoutId);

      // TODO this is probably wildly different for every microphone, we need
      // to somehow figure out what "no noise" sounds like and what "speaking"
      // sounds like from a volume perspective.
      // We also would want a overall timeout to ensure it'll eventually send
      // if no words are spoken for a while even if the microphone appears to
      // be in use.
      if (microphoneVolumeLevelAvg > 0.006) {
        console.log(
          "Planned to send, but volume was too high",
          microphoneVolumeLevelAvg
        );
        // volume too high, check again later
        timeoutId = setTimeout(() => debouncedSendFinalResult(transcript), 200);
        return;
      }

      onFinalResult?.(transcript);
      recognition?.abort();
      setTimeout(() => {
        if (isRecordingRef.current) {
          try {
            console.log("start 1");
            recognition?.start();
          } catch (e) {
            // Probably already started, ignore
            console.log("err 1", e);
          }
        }
      }, 300);
    };

    recognition.onresult = (event) => {
      const results = [...event.results].map((result) => result[0].transcript);
      const fullTranscript = results.join("");

      let transcript = fullTranscript;
      if (resultsContainPriorResults(results)) {
        transcript = results[results.length - 1];
      }

      const isFinal = event.results[event.results.length - 1].isFinal;

      console.log(results, transcript, isFinal);

      onNewResult?.(transcript);

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        debouncedSendFinalResult(transcript);
      }, 1200);
    };

    recognition.onend = () => {
      if (isRecordingRef.current) {
        try {
          recognition?.start();
          console.log("start 3");
        } catch (e) {
          // Probably already started, ignore
          console.log("err 3", e);
        }
      }
    };
  }, [onNewResult, onFinalResult]);

  return {
    isRecording,
    start: () => {
      setIsRecording(true);
      isRecordingRef.current = true;
      try {
        console.log("start 2");
        recognition?.start();
      } catch (e) {
        // Probably already started, ignore
        console.log("err 2", e);
      }

      const isMobile = navigator.userAgent.includes("Mobile");
      if (!isMobile) {
        getMicrophoneVolume((vol) => {
          microphoneVolumeLevelAvg = (microphoneVolumeLevelAvg * 19 + vol) / 20;
        });
      }
    },
    stop: () => {
      console.log("stop 2");
      setIsRecording(false);
      isRecordingRef.current = false;
      recognition?.abort();
      stopListeningToVolume();
    },
    isSupported: recognition != null,
  };
}
