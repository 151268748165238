import { BABY_AGE_CUTOFF_DAYS, CHILD_AGE_CUTOFF_DAYS } from "../PatientIcon";
import { Patient } from "../PatientList";

export default function getPatientColor(patientData: Patient | null): string {
  if (patientData == null) {
    return "yellow";
  }

  if (patientData.ageDays < BABY_AGE_CUTOFF_DAYS) {
    return "yellow";
  }

  if (
    patientData.genderIdx === 0 &&
    patientData.ageDays < CHILD_AGE_CUTOFF_DAYS
  ) {
    return "blue";
  } else if (patientData.genderIdx === 0) {
    return "green";
  }

  if (
    patientData.genderIdx === 1 &&
    patientData.ageDays < CHILD_AGE_CUTOFF_DAYS
  ) {
    return "purple";
  } else if (patientData.genderIdx === 1) {
    return "red";
  }

  return "yellow";
}
