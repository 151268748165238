import * as React from "react";
import {
  faHospital,
  faHouseMedicalFlag,
  faVial,
} from "@fortawesome/free-solid-svg-icons";
import { PricingFeature } from "./PricingFeature";
import { PricingBox } from "./PricingBox";
import { animated } from "@react-spring/web";
import usePopIn from "./utils/usePopIn";

const AnimatedPricingBox = animated(PricingBox);

export function PricingOptions(props) {
  const styles = usePopIn({ amount: 3 });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: 20,
        width: "100%",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <AnimatedPricingBox
        name="Free"
        price="$0"
        frequency="Completely free!"
        icon={faVial}
        iconColor="hsl(210 73% 75% / 1)"
        iconBackground="hsl(210 73% 55% / 1)"
        cta={props.config.trial.cta}
        style={styles[0]}
      >
        <PricingFeature tooltip="The Free version allows you to create 4 AI patients per day.">
          4 patients per day
        </PricingFeature>
        <PricingFeature tooltip="You'll have access to the Counselling mode, allowing you to create AI patients with a condition of your choice and counsel them on it.">
          Counselling
        </PricingFeature>
        <PricingFeature tooltip="You'll have access to the History of Present Illness mode, allowing you to create AI patients with a random condition to practice focused and relevant history taking.">
          History of Present Illness
        </PricingFeature>
        <PricingFeature tooltip="You'll have access to the Pediatrics mode, allowing you to create AI patients taking in their children for routine checkups at various ages.">
          Pediatrics
        </PricingFeature>
        <PricingFeature tooltip="You'll have access to the Serious Illness Conversations mode, allowing you to create AI patients to deliver difficult news to so you can practice your delivery skills.">
          Serious Illness Conversations
        </PricingFeature>
      </AnimatedPricingBox>
      <AnimatedPricingBox
        name="Monthly Subscription"
        price="$50"
        frequency="Billed monthly"
        icon={faHospital}
        iconColor="hsl(210 73% 75% / 1)"
        iconBackground="hsl(210 73% 55% / 1)"
        cta={props.config.monthly.cta}
        big
        style={styles[1]}
      >
        <PricingFeature tooltip="The Monthly Subscription allows you to create 16 AI patients each day.">
          16 patients per day
        </PricingFeature>
        <PricingFeature tooltip="You'll have access to the Counselling mode, allowing you to create AI patients with a condition of your choice and counsel them on it.">
          Counselling
        </PricingFeature>
        <PricingFeature tooltip="You'll have access to the History of Present Illness mode, allowing you to create AI patients with a random condition to practice focused and relevant history taking.">
          History of Present Illness
        </PricingFeature>
        <PricingFeature tooltip="You'll have access to the Pediatrics mode, allowing you to create AI patients taking in their children for routine checkups at various ages.">
          Pediatrics
        </PricingFeature>
        <PricingFeature tooltip="You'll have access to the Serious Illness Conversations mode, allowing you to create AI patients to deliver difficult news to so you can practice your delivery skills.">
          Serious Illness Conversations
        </PricingFeature>
      </AnimatedPricingBox>
      <AnimatedPricingBox
        name="Annual Subscription"
        price="$300"
        frequency="Billed annually"
        icon={faHouseMedicalFlag}
        iconColor="hsl(210 73% 75% / 1)"
        iconBackground="hsl(210 73% 55% / 1)"
        cta={props.config.yearly.cta}
        style={styles[2]}
        tag={
          <div
            style={{
              borderRadius: 32,
              background:
                "linear-gradient(0deg, rgba(46, 134, 222, 0.3) 0%, rgba(84, 160, 255, 0.5) 100%)",
              padding: "4px 16px",
              fontSize: 12,
              fontWeight: 300,
              border: "1px solid rgba(46, 134, 222, 0.4)",
            }}
          >
            50% LESS
          </div>
        }
      >
        <PricingFeature tooltip="The Annual Subscription allows you to create an unlimited number of patients, with no daily limit.">
          Unlimited patients
        </PricingFeature>
        <PricingFeature tooltip="You'll have access to the Counselling mode, allowing you to create AI patients with a condition of your choice and counsel them on it.">
          Counselling
        </PricingFeature>
        <PricingFeature tooltip="You'll have access to the History of Present Illness mode, allowing you to create AI patients with a random condition to practice focused and relevant history taking.">
          History of Present Illness
        </PricingFeature>
        <PricingFeature tooltip="You'll have access to the Pediatrics mode, allowing you to create AI patients taking in their children for routine checkups at various ages.">
          Pediatrics
        </PricingFeature>
        <PricingFeature tooltip="You'll have access to the Serious Illness Conversations mode, allowing you to create AI patients to deliver difficult news to so you can practice your delivery skills.">
          Serious Illness Conversations
        </PricingFeature>
      </AnimatedPricingBox>
    </div>
  );
}
