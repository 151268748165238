import * as React from "react";
import LoadingSpinnerWithText from "./LoadingSpinnerWithText";

export function FullPageLoadingSpinner(props) {
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingSpinnerWithText {...props} />
    </div>
  );
}
