import { useEffect, useState } from "react";

export default function useMouseEvents() {
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const mouseup = () => {
      if (active) {
        setActive(false);
      }
    };

    window.addEventListener("mouseup", mouseup);

    return () => {
      window.removeEventListener("mouseup", mouseup);
    };
  }, [active]);

  return {
    props: {
      onMouseDown: () => {
        setActive(true);
      },
      onMouseEnter: () => {
        setHover(true);
      },
      onMouseLeave: () => {
        setHover(false);
      },
    },
    active,
    hover,
  };
}
