import * as React from "react";
import { Button } from "./Button";
import { Patient } from "./PatientList";
import { getYearMonthDay } from "./utils/getYearMonthDay";
import { getGenderReadable } from "../../functions/utils/getGenderReadable";
import uppercaseFirst from "./utils/uppercaseFirst";
import PatientIcon from "./PatientIcon";
import { faMagnifyingGlassArrowRight } from "@fortawesome/free-solid-svg-icons";

function ConditionBox({
  patientData,
  hideRevealButton,
}: {
  patientData: Patient | null;
  hideRevealButton?: boolean;
}) {
  const [isRevealed, setIsRevealed] = React.useState(
    patientData?.type !== "diagnosis"
  );

  if (
    patientData?.type === "pediatrics" ||
    patientData?.type === "difficult" ||
    hideRevealButton
  ) {
    return null;
  }

  if (isRevealed) {
    return (
      <span>
        <strong>Condition:</strong> {patientData?.condition}
      </span>
    );
  }

  return (
    <Button
      onClick={() => {
        setIsRevealed(true);
      }}
      icon={faMagnifyingGlassArrowRight}
    >
      Reveal Condition
    </Button>
  );
}

function getReadableType(type: string): string {
  if (type === "diagnosis") {
    return "History of Present Illness";
  }
  if (type === "pediatrics") {
    return "Pediatrics";
  }
  if (type === "counselling") {
    return "Counselling";
  }
  if (type === "difficult") {
    return "Serious Illness Conversation";
  }

  throw new Error("Invalid type");
}

export function PatientInfo({
  patientData,
  hideRevealButton,
}: {
  patientData: Patient | null;
  hideRevealButton?: boolean;
}) {
  return (
    <>
      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
        <PatientIcon patientData={patientData} iconSize="lg" />
        <h1 style={{ margin: 0 }}>
          {patientData?.name != null
            ? uppercaseFirst(patientData.name)
            : "Patient"}
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          alignItems: "start",
        }}
      >
        {patientData?.createdAt != null && (
          <div>
            <strong>Created:</strong>{" "}
            {new Date(patientData.createdAt._seconds * 1000).toLocaleDateString(
              undefined,
              {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
              }
            )}
          </div>
        )}
        {patientData?.type != null && (
          <div>
            <strong>Type:</strong> {getReadableType(patientData.type)}
          </div>
        )}
        {patientData?.ageDays != null && (
          <div>
            <strong>Age:</strong> {getYearMonthDay(patientData.ageDays)}(s) old
          </div>
        )}
        {patientData?.genderIdx != null && (
          <div>
            <strong>Gender:</strong>{" "}
            {uppercaseFirst(
              getGenderReadable(patientData.ageDays, patientData.genderIdx)
            )}
          </div>
        )}
        <ConditionBox
          patientData={patientData}
          hideRevealButton={hideRevealButton}
        />
      </div>
    </>
  );
}
