import {
  faBaby,
  faChild,
  faChildDress,
  faCommentMedical,
  faPerson,
  faPersonDress,
} from "@fortawesome/free-solid-svg-icons";
import { Patient } from "./PatientList";
import React from "react";
import { IconWithBackground } from "./IconWithBackground";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import getPatientColor from "./utils/getPatientColor";

export const BABY_AGE_CUTOFF_DAYS = 730;
export const CHILD_AGE_CUTOFF_DAYS = 4380;

export default function PatientIcon({
  patientData,
  size,
  iconSize,
}: {
  patientData: Patient | null;
  size?: number;
  iconSize?: SizeProp;
}) {
  if (patientData == null) {
    return (
      <IconWithBackground
        size={size}
        iconSize={iconSize}
        icon={faCommentMedical}
        color={getPatientColor(patientData)}
      />
    );
  }

  if (patientData.ageDays < BABY_AGE_CUTOFF_DAYS) {
    return (
      <IconWithBackground
        size={size}
        iconSize={iconSize}
        icon={faBaby}
        color={getPatientColor(patientData)}
      />
    );
  }

  if (
    patientData.genderIdx === 0 &&
    patientData.ageDays < CHILD_AGE_CUTOFF_DAYS
  ) {
    return (
      <IconWithBackground
        size={size}
        iconSize={iconSize}
        icon={faChild}
        color={getPatientColor(patientData)}
      />
    );
  } else if (patientData.genderIdx === 0) {
    return (
      <IconWithBackground
        size={size}
        iconSize={iconSize}
        icon={faPerson}
        color={getPatientColor(patientData)}
      />
    );
  }

  if (
    patientData.genderIdx === 1 &&
    patientData.ageDays < CHILD_AGE_CUTOFF_DAYS
  ) {
    return (
      <IconWithBackground
        size={size}
        iconSize={iconSize}
        icon={faChildDress}
        color={getPatientColor(patientData)}
      />
    );
  } else if (patientData.genderIdx === 1) {
    return (
      <IconWithBackground
        size={size}
        iconSize={iconSize}
        icon={faPersonDress}
        color={getPatientColor(patientData)}
      />
    );
  }

  return (
    <IconWithBackground
      size={size}
      iconSize={iconSize}
      icon={faCommentMedical}
      color={getPatientColor(patientData)}
    />
  );
}
