import * as React from "react";
import { Link } from "react-router-dom";
import logo from "../img/logo1024white.png";

export default function Header(props) {
  return (
    <div
      style={{
        background: props.background,
        color: "#eee",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 80,
          width: "min(1100px, 100vw)",
          alignItems: "center",
          padding: "8px 20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            flexWrap: "wrap",
            gap: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 20,
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: 32,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link
                to="/"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                  color: "#eee",
                }}
              >
                <img
                  width={48}
                  height={48}
                  src={logo}
                  style={{ filter: "brightness(93.33%)" }}
                />
                COMPATIENT
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                gap: 20,
                justifyContent: "center",
              }}
            >
              {props.middleContent}
            </div>
          </div>
          <div
            style={{
              textAlign: "right",
              fontSize: 16,
            }}
          >
            {props.rightContent}
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
}
