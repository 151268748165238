import * as React from "react";
import { getThemeColor } from "./utils/getThemeColor";
import { useSpring, animated } from "@react-spring/web";

export default function LoadingSpinner({
  size,
  color,
  delay,
  inline,
}: {
  size?: number;
  color?: string;
  delay?: number;
  inline?: boolean;
}) {
  const start = {
    borderRadius: "50%",
    rotate: "0deg",
    scale: 0.5,
    boxShadow: "0 0 5px -2px #888",
    opacity: 0.5,
  };

  const end = {
    borderRadius: "12px",
    rotate: "720deg",
    scale: 1.2,
    boxShadow: "0 0 20px -5px #888",
    opacity: 1,
  };

  const [style] = useSpring(
    () => ({
      from: start,
      to: [end, start],
      loop: true,
      delay,
      config: {
        mass: 5,
        friction: 80,
        tension: 600,
      },
    }),
    []
  );

  return (
    <animated.div
      style={{
        background: color ?? getThemeColor("blue"),
        width: size ?? 40,
        height: size ?? 40,
        display: inline ? "inline-block" : "block",
        verticalAlign: "top",
        ...style,
      }}
    />
  );
}
