import * as React from "react";
import useAsyncEffect from "./utils/useAsyncEffect";
import callFirebaseFunction from "./utils/callFirebaseFunction";
import { Link } from "react-router-dom";
import { FullPageLoadingSpinner } from "./FullPageLoadingSpinner";
import { Button } from "./Button";
import { PatientInfo } from "./PatientInfo";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import usePopIn from "./utils/usePopIn";
import { animated } from "@react-spring/web";
import ContentBox from "./ContentBox";

export type Patient = {
  id: string;
  createdAt: { _seconds: number };
  name: string;
  genderIdx: number;
  condition: string;
  ageDays: number;
  type: string;
  messages?: Array<{ content: string; role: string }>;
};

type Patients = Array<Patient>;

const AnimatedContentBox = animated(ContentBox);

export function PatientList() {
  const [patients, setPatients] = React.useState<null | Patients>(null);

  useAsyncEffect(async () => {
    const patients = await callFirebaseFunction<{
      patients: Patients;
    }>("getPatients", {});

    return patients.patients;
  }, setPatients);

  const styles = usePopIn({ amount: (patients?.length ?? 0) + 1 });

  if (patients == null) {
    return <FullPageLoadingSpinner label="Loading patients" />;
  }

  const data = patients.map((patient, idx) => {
    return (
      <AnimatedContentBox
        key={patient.id}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          width: 360,
          height: 260,
          padding: 16,
          border: "none",
          justifyContent: "space-between",
          ...styles[idx + 1],
        }}
      >
        <PatientInfo patientData={patient} hideRevealButton />
        <Button href={"/patient/" + patient.id} icon={faComment}>
          Open Chat
        </Button>
      </AnimatedContentBox>
    );
  });

  const emptyState =
    patients.length === 0 ? (
      <div>
        You haven't created any patients yet.{" "}
        <Link to="/create">Create Patient</Link>
      </div>
    ) : null;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 20,
          padding: 20,
          flexDirection: "column",
          width: "100vw",
          alignItems: "center",
        }}
      >
        <animated.h1 style={styles[0]}>Patient History</animated.h1>
        <div
          style={{
            display: "flex",
            gap: 20,
            flexWrap: "wrap",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {emptyState}
          {data}
        </div>
      </div>
    </div>
  );
}
