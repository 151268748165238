import * as React from "react";
import callFirebaseFunction from "./utils/callFirebaseFunction";
import { signOut } from "firebase/auth";
import { auth } from "./utils/firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faArrowUpFromBracket,
  faCircleCheck,
  faEllipsis,
  faComment,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { getThemeColor } from "./utils/getThemeColor";
import { PricingOptions } from "./PricingOptions";
import { Button } from "./Button";
import { FullPageLoadingSpinner } from "./FullPageLoadingSpinner";
import useAsyncEffect from "./utils/useAsyncEffect";
import LoadingSpinner from "./LoadingSpinner";
import Banner from "./Banner";
import { useNavigate } from "react-router-dom";

type Tab = {
  label: string;
  content: React.ReactNode;
};

const TABS: Record<string, Tab> = {
  general: {
    label: "General",
    content: <General />,
  },
  feedback: {
    label: "Feedback",
    content: <FeedbackTab />,
  },
};

function FeedbackTab() {
  const name = auth.currentUser?.displayName ?? "";
  const email = auth.currentUser?.email ?? "";

  const [feedback, setFeedback] = React.useState("");
  const [sendState, setSendState] = React.useState<null | boolean>(null);
  const [error, setError] = React.useState("");

  return (
    <>
      <div style={{ fontSize: 14 }}>
        Report bugs, provide feedback, or request support using the form below.
        Please make sure to include as much information as possible! We'll reply
        back via email as soon as we can.
      </div>
      <form
        style={{ display: "flex", flexDirection: "column", gap: 8 }}
        onSubmit={async (e) => {
          e.stopPropagation();
          e.preventDefault();

          setError("");
          setSendState(false);
          try {
            await callFirebaseFunction("saveFeedback", {
              name,
              email,
              feedback,
            });
          } catch (e) {
            setSendState(null);
            setError(
              "Sorry, there was an error sending your feedback, please try again later."
            );
            return;
          }
          setSendState(true);
        }}
      >
        {error && <Banner type="error">{error}</Banner>}
        <input
          required
          style={{
            borderRadius: 8,
            border: "1px solid rgb(214, 235, 253)",
            padding: "12px 16px",
            fontFamily: '"Montserrat", sans-serif',
            fontSize: 14,
          }}
          value={name}
          disabled
          type="text"
          placeholder="Your name"
        />
        <input
          required
          style={{
            borderRadius: 8,
            border: "1px solid rgb(214, 235, 253)",
            padding: "12px 16px",
            fontFamily: '"Montserrat", sans-serif',
            fontSize: 14,
          }}
          value={email}
          disabled
          type="email"
          placeholder="Your email"
        />
        <textarea
          required
          style={{
            borderRadius: 8,
            border: "1px solid rgb(214, 235, 253)",
            padding: "12px 16px",
            fontFamily: '"Montserrat", sans-serif',
            fontSize: 14,
          }}
          disabled={sendState != null}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Your feedback, questions and comments"
        />
        <Button
          color={getThemeColor("yellow")}
          textColor="initial"
          icon={faComment}
          disabled={sendState != null}
          loading={sendState === false}
        >
          {sendState === true ? "Sent!" : "Send Feedback"}
        </Button>
      </form>
    </>
  );
}

function NavItem({
  isActive,
  label,
  onClick,
}: {
  isActive: boolean;
  label: string;
  onClick: () => void;
}) {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        gap: 12,
        alignItems: "center",
        fontWeight: isActive ? 600 : 300,
        borderBottom: isActive ? "2px solid " + getThemeColor("blue") : "none",
        padding: "12px",
        cursor: "pointer",
        color: isActive ? getThemeColor("blue") : "initial",
      }}
    >
      <div>{label}</div>
    </div>
  );
}

function SubscribeButton(props: { price: string; label: string; icon: any }) {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Button
      color={getThemeColor("yellow")}
      textColor="initial"
      width={"100%"}
      icon={props.icon}
      loading={isLoading}
      onClick={async () => {
        try {
          setIsLoading(true);
          const result = await callFirebaseFunction<{ url: string }>(
            "startSubscribe",
            {
              redirectSuccess:
                window.location.origin +
                window.location.pathname +
                "?subscribe=success",
              redirectCancel: window.location.origin + window.location.pathname,
              price: props.price,
            }
          );
          window.location.assign(result.url);
        } catch (e) {
          // TODO
          alert(e.message);
        }
        setIsLoading(false);
      }}
    >
      {props.label}
    </Button>
  );
}

function General() {
  const [currentSubscription, setCurrentSubscription] = React.useState<
    string | null
  >(null);
  const [isLoadingManagePayments, setIsLoadingManagePayments] =
    React.useState(false);
  const [isDowngradingPlan, setIsDowngradingPlan] = React.useState(false);

  useAsyncEffect(async () => {
    const sub = await callFirebaseFunction<{ subscription: string }>(
      "getSubscription",
      {}
    );
    return sub.subscription;
  }, setCurrentSubscription);

  const loadManagePayments = async (
    setter: (value: React.SetStateAction<boolean>) => void
  ) => {
    try {
      setter(true);
      const result = await callFirebaseFunction<{ url: string }>(
        "ext-firestore-stripe-payments-createPortalLink",
        {
          returnUrl: window.location.href,
        }
      );
      window.open(result.url, "_blank");
    } catch (e) {
      // TODO
      alert(e.message);
    }
    setter(false);
  };

  if (currentSubscription == null) {
    return <FullPageLoadingSpinner />;
  }

  const currentPlanButton = (
    <Button disabled icon={faCircleCheck} color={getThemeColor("green")}>
      Current Plan
    </Button>
  );

  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        gap: 80,
        alignItems: "center",
      }}
    >
      <PricingOptions
        config={{
          trial: {
            cta:
              currentSubscription === "free" ? (
                currentPlanButton
              ) : (
                <Button
                  onClick={async () =>
                    await loadManagePayments(setIsDowngradingPlan)
                  }
                  disabled={isDowngradingPlan}
                  loading={isDowngradingPlan}
                  icon={faArrowDown}
                >
                  Downgrade
                </Button>
              ),
          },
          monthly: {
            cta:
              currentSubscription === "monthly" ? (
                currentPlanButton
              ) : currentSubscription === "yearly" ? (
                <SubscribeButton
                  price="price_1OYhShDG4TqM4JFlcIz59Moy"
                  label="Change To Monthly"
                  icon={faEllipsis}
                />
              ) : (
                <SubscribeButton
                  price="price_1OYhShDG4TqM4JFlcIz59Moy"
                  label="Upgrade"
                  icon={faArrowUpFromBracket}
                />
              ),
          },
          yearly: {
            cta:
              currentSubscription === "yearly" ? (
                currentPlanButton
              ) : (
                <SubscribeButton
                  price="price_1OYhTaDG4TqM4JFlauHLyrmS"
                  label="Upgrade"
                  icon={faArrowUpFromBracket}
                />
              ),
          },
        }}
      />
      {(currentSubscription === "monthly" ||
        currentSubscription === "yearly") && (
        <a
          style={{
            cursor: "pointer",
            textAlign: "center",
          }}
          onClick={async () =>
            await loadManagePayments(setIsLoadingManagePayments)
          }
        >
          Manage Payments (Cancel/change subscription manage payment methods,
          etc)
          {isLoadingManagePayments && (
            <>
              {" "}
              <LoadingSpinner size={16} inline />
            </>
          )}
        </a>
      )}
    </div>
  );
}

export default function SettingsPage() {
  const [tabId, setTabId] = React.useState("general");
  const navigate = useNavigate();

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (
      queryParams.has("subscribe") &&
      queryParams.get("subscribe") === "success"
    ) {
      gtag("event", "conversion", {
        send_to: "AW-11481660388/fWnFCL_f2YsZEOT38OIq",
      });
    }
  }, []);

  const tabOptions = Object.keys(TABS).map((loopTabId) => {
    return (
      <NavItem
        label={TABS[loopTabId].label}
        isActive={loopTabId === tabId}
        onClick={() => setTabId(loopTabId)}
      />
    );
  });

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 20,
          padding: 20,
          flexDirection: "column",
          width: "100vw",
          maxWidth: 1100,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #ddd",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex" }}>{tabOptions}</div>
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              gap: 8,
              alignItems: "center",
              fontWeight: 300,
            }}
            onClick={() => {
              signOut(auth).then(() => {
                navigate("/");
              });
            }}
          >
            <FontAwesomeIcon icon={faRightFromBracket} color={"#555"} /> Log out
          </div>
        </div>
        {TABS[tabId].content}
      </div>
    </div>
  );
}
