function clamp(number: number, min: number, max: number): number {
  return Math.max(Math.min(number, max), min);
}

// Take 2 digits at a time
function convert(color: string, percent: number): string {
  return clamp(parseInt(color, 16) + percent, 0, 255)
    .toString(16)
    .padStart(2, "0");
}

export default function lightenDarkenColor(
  color: string,
  percent: number
): string {
  if (!color.startsWith("#")) {
    throw new Error(
      "Invalid hex color format. Please provide a color in hex format starting with #."
    );
  }

  const r = convert(color.substring(1, 3), percent);
  const g = convert(color.substring(3, 5), percent);
  const b = convert(color.substring(5, 7), percent);

  if (color.length > 7) {
    const a = convert(color.substring(7, 9), percent);
    return `#${r}${g}${b}${a}`;
  }
  return `#${r}${g}${b}`;
}
