import * as React from "react";
import LoadingSpinner from "./LoadingSpinner";

export default function LoadingSpinnerWithText(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 40,
        alignItems: "center",
        padding: "40px 20px",
      }}
    >
      <LoadingSpinner />
      <div
        style={{
          textTransform: "uppercase",
          fontSize: 18,
          fontWeight: 800,
          letterSpacing: 4,
        }}
      >
        {props.label ?? "Loading"}
      </div>
    </div>
  );
}
