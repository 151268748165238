import * as React from "react";
import { animated, useSpring } from "@react-spring/web";
import usePopIn from "./utils/usePopIn";

function AnimatedText(props: { children: React.ReactNode }) {
  const style = useSpring({
    from: {
      opacity: 0,
      translateX: 20,
    },
    to: {
      opacity: 1,
      translateX: 0,
    },
  });

  return (
    <animated.div
      style={{
        position: "absolute",
        top: 0,
        left: -200,
        right: -200,
        bottom: 0,
        textAlign: "center",
        textDecoration: "underline",
        textDecorationColor: "rgba(255, 255, 255, 0.2)",
        ...style,
      }}
    >
      {props.children}
    </animated.div>
  );
}
function AnimatedTextSwapper({ texts }: { texts: Array<string> }) {
  const [textIdx, setTextIdx] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTextIdx((old) => (old + 1) % texts.length);
    }, 3250);

    return () => clearInterval(interval);
  });

  return (
    <span
      style={{
        position: "relative",
        whiteSpace: "nowrap",
      }}
    >
      <span style={{ opacity: 0 }}>{texts[0]}</span>
      <AnimatedText key={texts[textIdx]}>{texts[textIdx]}</AnimatedText>
    </span>
  );
}
export function HeroText() {
  const styles = usePopIn({ amount: 3 });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        alignItems: "center",
        padding: "0 20px",
        containerType: "inline-size",
        width: "100%",
      }}
    >
      <animated.div
        style={{
          borderRadius: 32,
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.25) 100%)",
          padding: "8px 12px",
          fontSize: 12,
          fontWeight: 800,
          border: "1px solid rgba(255, 255, 255, 0.5)",
          ...styles[0],
        }}
      >
        NOW AVAILABLE
      </animated.div>

      <style>{`
        .mainText {
          font-size: 52px;
        }

        @container (max-width: 520px) {
          .mainText {
            font-size: 48px;
          }
        }

        @container (max-width: 480px) {
          .mainText {
            font-size: 42px;
          }
        }

        @container (max-width: 400px) {
          .mainText {
            font-size: 38px;
          }
        }

        @container (max-width: 375px) {
          .mainText {
            font-size: 32px;
          }
        }

        @container (max-width: 320px) {
          .mainText {
            font-size: 28px;
          }
        }
      `}</style>
      <animated.div
        className="mainText"
        style={{
          maxWidth: 600,
          textAlign: "center",
          fontWeight: 600,
          ...styles[1],
        }}
      >
        <div>Practice your</div>
        <AnimatedTextSwapper
          texts={[
            "clinical skills",
            "bedside manner",
            "history taking",
            "counselling",
            "pediatrics",
            "serious conversations",
          ]}
        />
        <div>with AI patients</div>
      </animated.div>

      <animated.div
        style={{
          maxWidth: 400,
          fontSize: 16,
          fontWeight: 300,
          textAlign: "center",
          ...styles[2],
        }}
      >
        It's the best way to practice for the OSCE and other practical exams.
      </animated.div>
    </div>
  );
}
