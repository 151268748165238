import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HorizontalDivider } from "./HorizontalDivider";
import ContentBox from "./ContentBox";

export function PricingBox(props) {
  return (
    <ContentBox
      style={{
        padding: props.big ? "40px 20px" : 20,
        display: "flex",
        flexDirection: "column",
        gap: 20,
        flexGrow: props.big ? 1.2 : 1,
        flexBasis: 0,
        minWidth: 260,
        ...props.style,
      }}
    >
      <div
        style={{
          width: 60,
          height: 60,
          background: props.iconBackground,
          borderRadius: 12,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 16,
          gap: 4,
        }}
      >
        <FontAwesomeIcon icon={props.icon} color={props.iconColor} size="2x" />
      </div>
      <div style={{ fontSize: 20, fontWeight: 600 }}>{props.name}</div>
      <div>
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <div style={{ fontSize: 32, fontWeight: 500 }}>{props.price}</div>{" "}
          {props.tag}
        </div>
        <div style={{ fontSize: 14, fontWeight: 300 }}>{props.frequency}</div>
      </div>
      {props.cta}
      <HorizontalDivider />
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <div style={{ fontSize: 18, fontWeight: 300 }}>Includes</div>
        {props.children}
      </div>
    </ContentBox>
  );
}
