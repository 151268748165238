import { httpsCallable } from "@firebase/functions";
import { functions } from "./firebase";

export default async function callFirebaseFunction<TResult>(
  endpoint: string,
  params: any
) {
  const resp = await httpsCallable<any, TResult>(functions, endpoint)(params);

  return resp.data;
}
