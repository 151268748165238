import * as React from "react";

export default function useAsyncEffect<T>(
  callback: () => Promise<T>,
  setResult: (result: T) => void
) {
  React.useEffect(() => {
    let isCanceled = false;

    const asyncFn = async () => {
      const res = await callback();
      if (!isCanceled) {
        setResult(res);
      }
    };

    asyncFn();

    return () => {
      isCanceled = true;
    };
  }, []);
}
