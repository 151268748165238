import { useTrail } from "@react-spring/web";

export default function usePopIn(options: {
  amount: number;
  translateY?: number;
}) {
  return useTrail(options.amount, {
    from: { opacity: 0, translateY: options.translateY ?? 100 },
    to: { opacity: 1, translateY: 0 },
  });
}
