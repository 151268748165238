import * as React from "react";
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { getThemeColorDeemphasized } from "./utils/getThemeColorDeemphasized";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getThemeColor } from "./utils/getThemeColor";

function getColor(type: "success" | "error" | "info") {
  if (type === "success") {
    return "green";
  }

  if (type === "info") {
    return "lightblue";
  }

  return "red";
}

function getIcon(type: "success" | "error" | "info") {
  if (type === "success") {
    return faCheckCircle;
  }

  if (type === "info") {
    return faInfoCircle;
  }

  return faExclamationCircle;
}

export default function Banner(props: {
  children: React.ReactNode;
  type: "success" | "error" | "info";
}) {
  const color = getColor(props.type);
  const icon = getIcon(props.type);

  return (
    <div
      style={{
        background: getThemeColorDeemphasized(color),
        display: "flex",
        padding: 20,
        borderRadius: 12,
        gap: 20,
        alignItems: "center",
        boxShadow: "0 0 6px -2px rgb(214, 235, 253)",
      }}
    >
      <div style={{ flexShrink: 0 }}>
        <FontAwesomeIcon icon={icon} color={getThemeColor(color)} />
      </div>
      {props.children}
    </div>
  );
}
