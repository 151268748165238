import * as React from "react";

export default function ContentBox(props: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  faded?: boolean;
}) {
  return (
    <div
      style={{
        borderRadius: 12,
        boxShadow: "0 0 6px -2px rgb(214, 235, 253)",
        background: props.faded ? "#fafafa" : "white",
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
}
