{
  "conditions": [
    {
      "name": "Chickenpox",
      "minAgeMonths": 36,
      "maxAgeMonths": 540,
      "genders": [0, 1]
    },
    {
      "name": "Coxsackie viral infection",
      "minAgeMonths": 12,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "German measles (Rubella)",
      "minAgeMonths": 12,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Measles (Rubeola)",
      "minAgeMonths": 12,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Infectious mononucleosis",
      "minAgeMonths": 180,
      "maxAgeMonths": 420,
      "genders": [0, 1]
    },
    {
      "name": "Mumps",
      "minAgeMonths": 60,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Strep throat",
      "minAgeMonths": 60,
      "maxAgeMonths": 600,
      "genders": [0, 1]
    },
    {
      "name": "Syphilis",
      "minAgeMonths": 216,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Hepatitis A",
      "minAgeMonths": 180,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Hepatitis B",
      "minAgeMonths": 240,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Hepatitis C",
      "minAgeMonths": 240,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "HIV",
      "minAgeMonths": 240,
      "maxAgeMonths": 660,
      "genders": [0, 1]
    },
    {
      "name": "Fifth disease",
      "minAgeMonths": 60,
      "maxAgeMonths": 180,
      "genders": [0, 1]
    },
    {
      "name": "Roseola",
      "minAgeMonths": 6,
      "maxAgeMonths": 36,
      "genders": [0, 1]
    },
    {
      "name": "Diabetes type I",
      "minAgeMonths": 48,
      "maxAgeMonths": 660,
      "genders": [0, 1]
    },
    {
      "name": "Diabetes type II",
      "minAgeMonths": 156,
      "maxAgeMonths": 696,
      "genders": [0, 1]
    },
    {
      "name": "Juvenile Hypothyroidism",
      "minAgeMonths": 72,
      "maxAgeMonths": 144,
      "genders": [0, 1]
    },
    {
      "name": "Hypothyroidism",
      "minAgeMonths": 360,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Obesity",
      "minAgeMonths": 120,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Cushing's syndrome",
      "minAgeMonths": 240,
      "maxAgeMonths": 600,
      "genders": [0, 1]
    },
    {
      "name": "Gout",
      "minAgeMonths": 420,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Hyperthyroidism",
      "minAgeMonths": 240,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Anxiety disorder",
      "minAgeMonths": 156,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Depression",
      "minAgeMonths": 156,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Anorexia/bulimia",
      "minAgeMonths": 168,
      "maxAgeMonths": 204,
      "genders": [0, 1]
    },
    {
      "name": "Attention deficit hyperactivity disorder",
      "minAgeMonths": 60,
      "maxAgeMonths": 192,
      "genders": [0, 1]
    },
    {
      "name": "Attention deficit disorder",
      "minAgeMonths": 216,
      "maxAgeMonths": 600,
      "genders": [0, 1]
    },
    {
      "name": "Obsessive compulsive disorder",
      "minAgeMonths": 216,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Bipolar Disorder Type I",
      "minAgeMonths": 240,
      "maxAgeMonths": 420,
      "genders": [0, 1]
    },
    {
      "name": "Bipolar Disorder Type I",
      "minAgeMonths": 240,
      "maxAgeMonths": 420,
      "genders": [0, 1]
    },
    {
      "name": "Hypochondriasis",
      "minAgeMonths": 240,
      "maxAgeMonths": 480,
      "genders": [0, 1]
    },
    {
      "name": "Alcohol use disorder",
      "minAgeMonths": 216,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Substance use disorder",
      "minAgeMonths": 216,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Post traumatic stress disorder",
      "minAgeMonths": 216,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Autistic spectrum disorder",
      "minAgeMonths": 36,
      "maxAgeMonths": 72,
      "genders": [0, 1]
    },
    {
      "name": "Fetal alcohol spectrum disorder",
      "minAgeMonths": 8,
      "maxAgeMonths": 96,
      "genders": [0, 1]
    },
    {
      "name": "Migraine",
      "minAgeMonths": 84,
      "maxAgeMonths": 540,
      "genders": [0, 1]
    },
    {
      "name": "Tension Headache",
      "minAgeMonths": 84,
      "maxAgeMonths": 540,
      "genders": [0, 1]
    },
    {
      "name": "Beurger’s disease",
      "minAgeMonths": 240,
      "maxAgeMonths": 540,
      "genders": [0, 1]
    },
    {
      "name": "Bell's palsy",
      "minAgeMonths": 180,
      "maxAgeMonths": 720,
      "genders": [0, 1]
    },
    {
      "name": "a febrile seizure disorder",
      "minAgeMonths": 6,
      "maxAgeMonths": 64,
      "genders": [0, 1]
    },
    {
      "name": "a seizure disorder",
      "minAgeMonths": 60,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Meningitis",
      "minAgeMonths": 12,
      "maxAgeMonths": 420,
      "genders": [0, 1]
    },
    {
      "name": "Benign essential tremors",
      "minAgeMonths": 480,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Delirium",
      "minAgeMonths": 720,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Herpes zoster",
      "minAgeMonths": 600,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Restless leg syndrome",
      "minAgeMonths": 360,
      "maxAgeMonths": 660,
      "genders": [0, 1]
    },
    {
      "name": "Trigeminal neuralgia",
      "minAgeMonths": 600,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Parkinson's disease",
      "minAgeMonths": 624,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Multiple sclerosis",
      "minAgeMonths": 240,
      "maxAgeMonths": 540,
      "genders": [0, 1]
    },
    {
      "name": "Transient ischemic attack",
      "minAgeMonths": 480,
      "maxAgeMonths": 720,
      "genders": [0, 1]
    },
    {
      "name": "Peripheral neuropathy",
      "minAgeMonths": 696,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Alzheimer's disease and related dementias",
      "minAgeMonths": 780,
      "maxAgeMonths": 1056,
      "genders": [0, 1]
    },
    {
      "name": "Blepharitis",
      "minAgeMonths": 96,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Chalazion",
      "minAgeMonths": 144,
      "maxAgeMonths": 708,
      "genders": [0, 1]
    },
    {
      "name": "Conjunctivitis",
      "minAgeMonths": 6,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Simple corneal abrasion",
      "minAgeMonths": 36,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Nasolacrimal duct obstruction",
      "minAgeMonths": 2,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Simple foreign body in nose",
      "minAgeMonths": 24,
      "maxAgeMonths": 60,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Hordeolum",
      "minAgeMonths": 60,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Strabismus",
      "minAgeMonths": 12,
      "maxAgeMonths": 48,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Cataracts",
      "minAgeMonths": 720,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Glaucoma",
      "minAgeMonths": 540,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Periorbital cellulitis",
      "minAgeMonths": 24,
      "maxAgeMonths": 540,
      "genders": [0, 1]
    },
    {
      "name": "Uveitis",
      "minAgeMonths": 240,
      "maxAgeMonths": 720,
      "genders": [0, 1]
    },
    {
      "name": "Otitis media",
      "minAgeMonths": 6,
      "maxAgeMonths": 456,
      "genders": [0, 1]
    },
    {
      "name": "Otitis externa",
      "minAgeMonths": 96,
      "maxAgeMonths": 540,
      "genders": [0, 1]
    },
    {
      "name": "Cerumen impaction",
      "minAgeMonths": 84,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Benign positional paroxysmal vertigo",
      "minAgeMonths": 480,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Foreign body in ear",
      "minAgeMonths": 24,
      "maxAgeMonths": 60,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Labyrinthitis",
      "minAgeMonths": 360,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Ménière's disease",
      "minAgeMonths": 288,
      "maxAgeMonths": 804,
      "genders": [0, 1]
    },
    {
      "name": "Anterior epistaxis",
      "minAgeMonths": 60,
      "maxAgeMonths": 528,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Gingivitis",
      "minAgeMonths": 132,
      "maxAgeMonths": 720,
      "genders": [0, 1]
    },
    {
      "name": "Sinusitis",
      "minAgeMonths": 84,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Peritonsillar abscess",
      "minAgeMonths": 144,
      "maxAgeMonths": 480,
      "genders": [0, 1]
    },
    {
      "name": "Tonsillitis",
      "minAgeMonths": 60,
      "maxAgeMonths": 396,
      "genders": [0, 1]
    },
    {
      "name": "Stomatitis",
      "minAgeMonths": 120,
      "maxAgeMonths": 540,
      "genders": [0, 1]
    },
    {
      "name": "Temporomandibular joint dysfunction",
      "minAgeMonths": 240,
      "maxAgeMonths": 480,
      "genders": [0, 1]
    },
    {
      "name": "Hypertension",
      "minAgeMonths": 180,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Peripheral vascular disease",
      "minAgeMonths": 600,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Stasis ulcers",
      "minAgeMonths": 660,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Superficial thrombophlebitis",
      "minAgeMonths": 540,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Varicose veins",
      "minAgeMonths": 540,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Innocent heart murmur",
      "minAgeMonths": 36,
      "maxAgeMonths": 156,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Rheumatic fever",
      "minAgeMonths": 60,
      "maxAgeMonths": 180,
      "genders": [0, 1]
    },
    {
      "name": "Heart failure",
      "minAgeMonths": 720,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Atrial Fibrillation",
      "minAgeMonths": 600,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Stable coronary artery disease",
      "minAgeMonths": 540,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Chronic stable angina pectoris",
      "minAgeMonths": 600,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Unstable angina",
      "minAgeMonths": 600,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Dyslipidemia",
      "minAgeMonths": 192,
      "maxAgeMonths": 720,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Asthma",
      "minAgeMonths": 60,
      "maxAgeMonths": 720,
      "genders": [0, 1]
    },
    {
      "name": "Bronchitis",
      "minAgeMonths": 24,
      "maxAgeMonths": 720,
      "genders": [0, 1]
    },
    {
      "name": "Bronchiolitis",
      "minAgeMonths": 2,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Influenza",
      "minAgeMonths": 18,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Nicotine dependence",
      "minAgeMonths": 192,
      "maxAgeMonths": 720,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Tuberculosis",
      "minAgeMonths": 36,
      "maxAgeMonths": 720,
      "genders": [0, 1]
    },
    {
      "name": "Epiglottitis",
      "minAgeMonths": 24,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Chronic obstructive pulmonary disease, mild to moderate",
      "minAgeMonths": 480,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Interstitial lung disease",
      "minAgeMonths": 480,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Croup",
      "minAgeMonths": 6,
      "maxAgeMonths": 36,
      "genders": [0, 1]
    },
    {
      "name": "Upper respiratory infection",
      "minAgeMonths": 18,
      "maxAgeMonths": 72,
      "genders": [0, 1]
    },
    {
      "name": "Community acquired pneumonia",
      "minAgeMonths": 720,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Pertussis",
      "minAgeMonths": 6,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Anal fissures",
      "minAgeMonths": 180,
      "maxAgeMonths": 480,
      "genders": [0, 1]
    },
    {
      "name": "Constipation",
      "minAgeMonths": 12,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Gastroesophogeal reflux disease",
      "minAgeMonths": 300,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Irritable bowel syndrome",
      "minAgeMonths": 144,
      "maxAgeMonths": 588,
      "genders": [0, 1]
    },
    {
      "name": "a parasitic infection (roundworm, pinworm)",
      "minAgeMonths": 60,
      "maxAgeMonths": 420,
      "genders": [0, 1]
    },
    {
      "name": "Peptic ulcer",
      "minAgeMonths": 108,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Dysphagia",
      "minAgeMonths": 6,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Inguinal Hernia",
      "minAgeMonths": 1,
      "maxAgeMonths": 960,
      "genders": [0, 1]
    },
    {
      "name": "Hiatal Hernia",
      "minAgeMonths": 12,
      "maxAgeMonths": 960,
      "genders": [0, 1]
    },
    {
      "name": "Umbilical Hernia",
      "minAgeMonths": 12,
      "maxAgeMonths": 960,
      "genders": [0, 1]
    },
    {
      "name": "Diverticular disease",
      "minAgeMonths": 480,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Hemorrhoids",
      "minAgeMonths": 240,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Celiac",
      "minAgeMonths": 108,
      "maxAgeMonths": 720,
      "genders": [0, 1]
    },
    {
      "name": "Cholecystitis",
      "minAgeMonths": 600,
      "maxAgeMonths": 840,
      "genders": [0, 1]
    },
    {
      "name": "Chronic inflammatory bowel disease",
      "minAgeMonths": 216,
      "maxAgeMonths": 420,
      "genders": [0, 1]
    },
    {
      "name": "Pancreatitis",
      "minAgeMonths": 420,
      "maxAgeMonths": 660,
      "genders": [0, 1]
    },
    {
      "name": "Gastroenteritis",
      "minAgeMonths": 12,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Lactose intolerance",
      "minAgeMonths": 24,
      "maxAgeMonths": 60,
      "genders": [0, 1]
    },
    {
      "name": "Failure to thrive",
      "minAgeMonths": 6,
      "maxAgeMonths": 12,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Encopresis",
      "minAgeMonths": 48,
      "maxAgeMonths": 120,
      "genders": [0, 1]
    },
    {
      "name": "Hyperbilirubinemia",
      "minAgeMonths": 0.25,
      "maxAgeMonths": 0.5,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Colic",
      "minAgeMonths": 1,
      "maxAgeMonths": 3,
      "genders": [0, 1]
    },
    {
      "name": "Lice",
      "minAgeMonths": 48,
      "maxAgeMonths": 456,
      "genders": [0, 1]
    },
    {
      "name": "Scabies",
      "minAgeMonths": 48,
      "maxAgeMonths": 456,
      "genders": [0, 1]
    },
    {
      "name": "Oral Thrush",
      "minAgeMonths": 3,
      "maxAgeMonths": 6,
      "genders": [0, 1]
    },
    {
      "name": "Tinea capitis",
      "minAgeMonths": 24,
      "maxAgeMonths": 660,
      "genders": [0, 1]
    },
    {
      "name": "Tinea cruis",
      "minAgeMonths": 144,
      "maxAgeMonths": 660,
      "genders": [0, 1]
    },
    {
      "name": "Tinea corporis",
      "minAgeMonths": 84,
      "maxAgeMonths": 660,
      "genders": [0, 1]
    },
    {
      "name": "Tinea pedis",
      "minAgeMonths": 192,
      "maxAgeMonths": 660,
      "genders": [0, 1]
    },
    {
      "name": "Onychomycosis",
      "minAgeMonths": 600,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Impetigo",
      "minAgeMonths": 24,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Folliculitis",
      "minAgeMonths": 60,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Furuncles",
      "minAgeMonths": 84,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Carbuncles",
      "minAgeMonths": 84,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Cellulitis",
      "minAgeMonths": 84,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Paronychia",
      "minAgeMonths": 84,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Warts (viral)",
      "minAgeMonths": 120,
      "maxAgeMonths": 540,
      "genders": [0, 1]
    },
    {
      "name": "Molluscum cantagiosum (viral)",
      "minAgeMonths": 12,
      "maxAgeMonths": 540,
      "genders": [0, 1]
    },
    {
      "name": "Herpes simplex",
      "minAgeMonths": 12,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Psoriasis",
      "minAgeMonths": 84,
      "maxAgeMonths": 720,
      "genders": [0, 1]
    },
    {
      "name": "Pityriasis rosea",
      "minAgeMonths": 120,
      "maxAgeMonths": 456,
      "genders": [0, 1]
    },
    {
      "name": "Malignant skin lesions",
      "minAgeMonths": 180,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Acne vulgaris",
      "minAgeMonths": 156,
      "maxAgeMonths": 204,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Atopic dermatitis (eczema)",
      "minAgeMonths": 6,
      "maxAgeMonths": 360,
      "genders": [0, 1]
    },
    {
      "name": "Contact dermatitis",
      "minAgeMonths": 60,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Seborrheic dermatitis adult",
      "minAgeMonths": 360,
      "maxAgeMonths": 720,
      "genders": [0, 1]
    },
    {
      "name": "Seborrheic dermatitis cradle cap",
      "minAgeMonths": 0.5,
      "maxAgeMonths": 11,
      "genders": [0, 1]
    },
    {
      "name": "Diaper rash",
      "minAgeMonths": 4,
      "maxAgeMonths": 15,
      "genders": [0, 1]
    },
    {
      "name": "Sunburn",
      "minAgeMonths": 72,
      "maxAgeMonths": 720,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Lyme disease",
      "minAgeMonths": 300,
      "maxAgeMonths": 720,
      "genders": [0, 1]
    },
    {
      "name": "Bursitis",
      "minAgeMonths": 180,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Cervicalgia",
      "minAgeMonths": 180,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Costochondritis",
      "minAgeMonths": 156,
      "maxAgeMonths": 780,
      "genders": [0, 1]
    },
    {
      "name": "Plantar fasciitis",
      "minAgeMonths": 96,
      "maxAgeMonths": 780,
      "genders": [0, 1]
    },
    {
      "name": "Tendinitis/tenosynovitis",
      "minAgeMonths": 456,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Meniscus and ligament tears",
      "minAgeMonths": 180,
      "maxAgeMonths": 780,
      "genders": [0, 1]
    },
    {
      "name": "Carpal tunnel syndrome",
      "minAgeMonths": 336,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Fibromyalgia",
      "minAgeMonths": 360,
      "maxAgeMonths": 576,
      "genders": [0, 1]
    },
    {
      "name": "Impingement syndrome of shoulder",
      "minAgeMonths": 576,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Osteoarthritis",
      "minAgeMonths": 600,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Osteoporosis",
      "minAgeMonths": 1,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Osteomyelitis",
      "minAgeMonths": 36,
      "maxAgeMonths": 60,
      "genders": [0, 1]
    },
    {
      "name": "Transient synovitis of hip",
      "minAgeMonths": 24,
      "maxAgeMonths": 96,
      "genders": [0, 1]
    },
    {
      "name": "Vesicoureteral reflux",
      "minAgeMonths": 6,
      "maxAgeMonths": 24,
      "genders": [0, 1]
    },
    {
      "name": "Septic arthritis",
      "minAgeMonths": 36,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Herniated disc",
      "minAgeMonths": 420,
      "maxAgeMonths": 720,
      "genders": [0, 1]
    },
    {
      "name": "Low back pain",
      "minAgeMonths": 564,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Subluxation of the radial head",
      "minAgeMonths": 24,
      "maxAgeMonths": 48,
      "genders": [0, 1]
    },
    {
      "name": "Patellofemoral syndrome",
      "minAgeMonths": 192,
      "maxAgeMonths": 420,
      "genders": [0, 1]
    },
    {
      "name": "Lower urinary tract infections",
      "minAgeMonths": 6,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Interstitial cystitis",
      "minAgeMonths": 240,
      "maxAgeMonths": 540,
      "genders": [0, 1]
    },
    {
      "name": "Pyelonephritis",
      "minAgeMonths": 24,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Primary nocturnal enuresis",
      "minAgeMonths": 60,
      "maxAgeMonths": 84,
      "genders": [0, 1]
    },
    {
      "name": "Urinary incontinence",
      "minAgeMonths": 600,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Nephrolithiasis",
      "minAgeMonths": 480,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Chronic kidney disease",
      "minAgeMonths": 720,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Ectopic pregnancy",
      "minAgeMonths": 192,
      "maxAgeMonths": 420,
      "genders": [1],
      "isPreg": true
    },
    {
      "name": "Preterm labor",
      "minAgeMonths": 192,
      "maxAgeMonths": 420,
      "genders": [1],
      "isPreg": true
    },
    {
      "name": "Premature rupture of membranes (PROM)",
      "minAgeMonths": 192,
      "maxAgeMonths": 420,
      "genders": [1],
      "isPreg": true
    },
    {
      "name": "Pre-eclampsia",
      "minAgeMonths": 192,
      "maxAgeMonths": 420,
      "genders": [1],
      "isPreg": true
    },
    {
      "name": "Eclampsia",
      "minAgeMonths": 192,
      "maxAgeMonths": 420,
      "genders": [1],
      "isPreg": true
    },
    {
      "name": "Miscarriage",
      "minAgeMonths": 192,
      "maxAgeMonths": 420,
      "genders": [1],
      "isPreg": true
    },
    {
      "name": "Placenta previa",
      "minAgeMonths": 192,
      "maxAgeMonths": 420,
      "genders": [1],
      "isPreg": true
    },
    {
      "name": "Placeta abrution",
      "minAgeMonths": 192,
      "maxAgeMonths": 420,
      "genders": [1],
      "isPreg": true
    },
    {
      "name": "Ovarian torsion",
      "minAgeMonths": 108,
      "maxAgeMonths": 720,
      "genders": [1]
    },
    {
      "name": "Testicular torsion",
      "minAgeMonths": 144,
      "maxAgeMonths": 720,
      "genders": [0]
    },
    {
      "name": "Post menopausal bleeding",
      "minAgeMonths": 660,
      "maxAgeMonths": 900,
      "genders": [1]
    },
    {
      "name": "Abnormal uterine bleeding",
      "minAgeMonths": 240,
      "maxAgeMonths": 450,
      "genders": [1]
    },
    {
      "name": "Acute renal failure",
      "minAgeMonths": 720,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Gestational hypertension",
      "minAgeMonths": 216,
      "maxAgeMonths": 540,
      "genders": [1],
      "isPreg": true
    },
    {
      "name": "Baby blues",
      "minAgeMonths": 216,
      "maxAgeMonths": 540,
      "genders": "",
      "wasPreg": true
    },
    {
      "name": "Postpartum depression",
      "minAgeMonths": 216,
      "maxAgeMonths": 540,
      "genders": [1],
      "wasPreg": true
    },
    {
      "name": "Hyperemesis gravidarum",
      "minAgeMonths": 216,
      "maxAgeMonths": 540,
      "genders": [1],
      "isPreg": true
    },
    {
      "name": "Gestational diabetes",
      "minAgeMonths": 216,
      "maxAgeMonths": 540,
      "genders": [1],
      "isPreg": true
    },
    {
      "name": "Wounds and lacerations",
      "minAgeMonths": 48,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Burns",
      "minAgeMonths": 36,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Human bite",
      "minAgeMonths": 48,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Cat bite",
      "minAgeMonths": 48,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Dog bite",
      "minAgeMonths": 48,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Arthropod bites and stings",
      "minAgeMonths": 48,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Poisoning",
      "minAgeMonths": 36,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Mild traumatic brain injury/concussion",
      "minAgeMonths": 156,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Clavical fracture",
      "minAgeMonths": 48,
      "maxAgeMonths": 144,
      "genders": [0, 1]
    },
    {
      "name": "Fractures-not requiring reduction or casting",
      "minAgeMonths": 300,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Balanitis",
      "minAgeMonths": 24,
      "maxAgeMonths": 480,
      "genders": [0]
    },
    {
      "name": "Epididymitis",
      "minAgeMonths": 192,
      "maxAgeMonths": 468,
      "genders": [0]
    },
    {
      "name": "Chlamydia",
      "minAgeMonths": 192,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Gonorrhea",
      "minAgeMonths": 192,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Benign prostatic hyperplasia",
      "minAgeMonths": 612,
      "maxAgeMonths": 900,
      "genders": [0]
    },
    {
      "name": "Erectile dysfunction",
      "minAgeMonths": 540,
      "maxAgeMonths": 900,
      "genders": [0]
    },
    {
      "name": "Prostatitis",
      "minAgeMonths": 396,
      "maxAgeMonths": 720,
      "genders": [0]
    },
    {
      "name": "Hydrocele",
      "minAgeMonths": 480,
      "maxAgeMonths": 900,
      "genders": [0]
    },
    {
      "name": "Varicocele",
      "minAgeMonths": 216,
      "maxAgeMonths": 420,
      "genders": [0]
    },
    {
      "name": "Paraphimosis",
      "minAgeMonths": 36,
      "maxAgeMonths": 48,
      "genders": [0]
    },
    {
      "name": "Child Phimosis",
      "minAgeMonths": 144,
      "maxAgeMonths": 204,
      "genders": [0],
      "isTopic": true
    },
    {
      "name": "Adult Phimosis",
      "minAgeMonths": 216,
      "maxAgeMonths": 300,
      "genders": [0],
      "isTopic": true
    },
    {
      "name": "Primary amenorrhea",
      "minAgeMonths": 180,
      "maxAgeMonths": 216,
      "genders": [1]
    },
    {
      "name": "Precocious puberty",
      "minAgeMonths": 84,
      "maxAgeMonths": 96,
      "genders": [0, 1]
    },
    {
      "name": "Dysmenorrhea",
      "minAgeMonths": 240,
      "maxAgeMonths": 516,
      "genders": [1]
    },
    {
      "name": "Pelvic inflammatory disease",
      "minAgeMonths": 192,
      "maxAgeMonths": 360,
      "genders": [1]
    },
    {
      "name": "Bacterial vaginosis",
      "minAgeMonths": 180,
      "maxAgeMonths": 450,
      "genders": [1]
    },
    {
      "name": "Trichomoniasis",
      "minAgeMonths": 180,
      "maxAgeMonths": 600,
      "genders": [1]
    },
    {
      "name": "Vaginal candidiasis",
      "minAgeMonths": 180,
      "maxAgeMonths": 600,
      "genders": [1]
    },
    {
      "name": "Atrophic vaginitis",
      "minAgeMonths": 540,
      "maxAgeMonths": 660,
      "genders": [1]
    },
    {
      "name": "Contraception",
      "minAgeMonths": 180,
      "maxAgeMonths": 420,
      "genders": [1],
      "isTopic": true
    },
    {
      "name": "Premenstrual syndrome",
      "minAgeMonths": 168,
      "maxAgeMonths": 516,
      "genders": [1]
    },
    {
      "name": "Simple ovarian cyst",
      "minAgeMonths": 180,
      "maxAgeMonths": 504,
      "genders": [1]
    },
    {
      "name": "Mastitis",
      "minAgeMonths": 264,
      "maxAgeMonths": 480,
      "genders": [1]
    },
    {
      "name": "Menopause",
      "minAgeMonths": 600,
      "maxAgeMonths": 684,
      "genders": [1]
    },
    {
      "name": "Polycystic ovary syndrome",
      "minAgeMonths": 240,
      "maxAgeMonths": 420,
      "genders": [1]
    },
    {
      "name": "Endometriosis",
      "minAgeMonths": 300,
      "maxAgeMonths": 480,
      "genders": [1]
    },
    {
      "name": "Polycythemia vera",
      "minAgeMonths": 600,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Phenylketonuria",
      "minAgeMonths": 1,
      "maxAgeMonths": 4,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Iron deficiency anemia",
      "minAgeMonths": 12,
      "maxAgeMonths": 780,
      "genders": [0, 1]
    },
    {
      "name": "Sickle cell anemia",
      "minAgeMonths": 5,
      "maxAgeMonths": 48,
      "genders": "",
      "isDx": true
    },
    {
      "name": "Sickle cell anemia",
      "minAgeMonths": 60,
      "maxAgeMonths": 420,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Allergic rhinitis",
      "minAgeMonths": 108,
      "maxAgeMonths": 420,
      "genders": [0, 1]
    },
    {
      "name": "Scoliosis",
      "minAgeMonths": 120,
      "maxAgeMonths": 180,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Brachial plexus injury",
      "minAgeMonths": 1,
      "maxAgeMonths": 3,
      "genders": [0, 1]
    },
    {
      "name": "Chronic fatigue syndrome",
      "minAgeMonths": 156,
      "maxAgeMonths": 780,
      "genders": [0, 1]
    },
    {
      "name": "Osgood-Schlatter disease",
      "minAgeMonths": 120,
      "maxAgeMonths": 180,
      "genders": [0, 1]
    },
    {
      "name": "Juvenile rheumatoid arthritis",
      "minAgeMonths": 24,
      "maxAgeMonths": 192,
      "genders": [0, 1]
    },
    {
      "name": "Rheumatoid arthritis",
      "minAgeMonths": 360,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "G6PD deficiency",
      "minAgeMonths": 1,
      "maxAgeMonths": 192,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Sjögren's syndrome",
      "minAgeMonths": 480,
      "maxAgeMonths": 708,
      "genders": [0, 1]
    },
    {
      "name": "Systemic lupus erythematosus",
      "minAgeMonths": 216,
      "maxAgeMonths": 540,
      "genders": [0, 1]
    },
    {
      "name": "Advanced Directive Care Planning",
      "minAgeMonths": 756,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Varicella Vaccine",
      "minAgeMonths": 12,
      "maxAgeMonths": 144,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Influenza Vaccine",
      "minAgeMonths": 6,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Hepatitis A Vaccine",
      "minAgeMonths": 6,
      "maxAgeMonths": 216,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Hepatitis B Vaccine",
      "minAgeMonths": 2,
      "maxAgeMonths": 708,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "HPV Vaccine",
      "minAgeMonths": 9,
      "maxAgeMonths": 540,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Meningococcal Vaccine",
      "minAgeMonths": 2,
      "maxAgeMonths": 300,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "MMR Vaccine",
      "minAgeMonths": 12,
      "maxAgeMonths": 72,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Tdap Vaccine",
      "minAgeMonths": 48,
      "maxAgeMonths": 216,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "DTap Vaccine",
      "minAgeMonths": 2,
      "maxAgeMonths": 18,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Td Vaccine",
      "minAgeMonths": 18,
      "maxAgeMonths": 720,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Rotavirus Vaccine",
      "minAgeMonths": 2,
      "maxAgeMonths": 4,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Pneumococcal Conjugate (PCV 13) Vaccine",
      "minAgeMonths": 2,
      "maxAgeMonths": 204,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Pneumococcal Polysaccharide (PPSV23) Vaccine",
      "minAgeMonths": 780,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Shingles Vaccine",
      "minAgeMonths": 600,
      "maxAgeMonths": 840,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Unplanned Pregnancy",
      "minAgeMonths": 180,
      "maxAgeMonths": 480,
      "genders": [1],
      "isTopic": true
    },
    {
      "name": "Injury from abuse",
      "minAgeMonths": 144,
      "maxAgeMonths": 900,
      "genders": [0, 1],
      "isTopic": true
    },
    {
      "name": "Retinal detachment",
      "minAgeMonths": 540,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Giant cell arteritis",
      "minAgeMonths": 600,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Compartment syndrome",
      "minAgeMonths": 144,
      "maxAgeMonths": 780,
      "genders": [0, 1]
    },
    {
      "name": "Appendicitis",
      "minAgeMonths": 120,
      "maxAgeMonths": 480,
      "genders": [0, 1]
    },
    {
      "name": "Suicidal ideation",
      "minAgeMonths": 192,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    },
    {
      "name": "Cauda equina syndrome",
      "minAgeMonths": 540,
      "maxAgeMonths": 900,
      "genders": [0, 1]
    }
  ]
}
