import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyClS3BTNDWfzTTl9j0WyEcYbtuMIwgATk8",
  authDomain: "osce-877d7.firebaseapp.com",
  projectId: "osce-877d7",
  storageBucket: "osce-877d7.appspot.com",
  messagingSenderId: "805976820426",
  appId: "1:805976820426:web:e1f54f2a6e3070bc7f0233",
  measurementId: "G-860RDLNNDM",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const functions = getFunctions(app);

if (process.env.NODE_ENV !== "production") {
  connectFunctionsEmulator(functions, window.location.hostname, 5001);
}
