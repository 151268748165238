import * as React from "react";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import brenda from "../img/brenda.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBabyCarriage,
  faVial,
  faComment,
  faFlask,
  faHandHoldingDollar,
  faHouse,
  faMicrophone,
  faNotesMedical,
  faRightToBracket,
  faStethoscope,
  faUserNurse,
  faPencil,
  faSadTear,
} from "@fortawesome/free-solid-svg-icons";
import Header from "./Header";
import callFirebaseFunction from "./utils/callFirebaseFunction";
import Banner from "./Banner";
import { animated } from "@react-spring/web";
import usePopIn from "./utils/usePopIn";
import { HorizontalDivider } from "./HorizontalDivider";
import { getThemeColor } from "./utils/getThemeColor";
import { PricingOptions } from "./PricingOptions";
import PatientPage from "./PatientPage";
import useAsyncEffect from "./utils/useAsyncEffect";
import { sleep } from "./utils/sleep";
import { HeroText } from "./HeroText";
import { log } from "./utils/log";

function SectionHeader(props) {
  return (
    <div style={{ fontSize: 40, fontWeight: 600, marginBottom: 40 }}>
      {props.children}
    </div>
  );
}

function VertialDivider() {
  return (
    <div
      style={{
        width: 1,
        background: "rgba(0, 0, 0, 0.1)",
      }}
    />
  );
}

function Footer() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          width: "min(960px, 100vw)",
          padding: 40,
          alignItems: "center",
        }}
      >
        <HorizontalDivider />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            textTransform: "uppercase",
            fontSize: 14,
            fontWeight: 300,
            color: "#555",
            gap: 12,
          }}
        >
          <div>Compatient</div>
          <div>Copyright © 2024</div>
          <div>
            <Link to="/terms">Terms</Link> &amp;{" "}
            <Link to="/privacy">Privacy</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function PreFooterUpsell() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        color: "#eee",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          width: "100vw",
          margin: "0 20px",
          padding: 40,
          background:
            "linear-gradient(0deg, " +
            getThemeColor("blue") +
            " 0%, " +
            getThemeColor("lightblue") +
            " 100%)",
          borderRadius: 12,
          alignItems: "center",
        }}
      >
        <div style={{ fontSize: 36, fontWeight: 600 }}>
          Practice makes perfect
        </div>
        <div style={{ fontSize: 14, fontWeight: 300 }}>
          Start practicing today for free.
        </div>
        <CTAs />
      </div>
    </div>
  );
}

const Testimonials = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div style={{ width: "100%" }} ref={ref}>
      <SectionHeader>What our customers think</SectionHeader>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 30,
          gap: 8,
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            maxWidth: 700,
            fontWeight: 500,
            fontSize: 30,
          }}
        >
          “The key to passing the OSCE is practice. Between working full time
          and moving I didn't think I could do it but this tool gave me the
          flexibility to practice and review all the conditions I needed to
          prepare for the exam. I don't think I would have passed on my first
          try without it.”
        </div>
        <div
          style={{
            flexShrink: 0,
            width: 126,
            height: 126,
            borderRadius: "50%",
            backgroundImage: "url(" + brenda + ")",
            backgroundSize: "100%",
          }}
        ></div>
      </div>
      <div style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}>
        Brenda Duong
      </div>
      <div style={{ fontWeight: 300, fontSize: 14 }}>NP(F), Vancouver BC</div>
    </div>
  );
});

function Feature(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: 40,
        flexWrap: "wrap",
      }}
    >
      <div
        style={{
          flexBasis: 200,
          flexShrink: 0,
          height: 200,
          borderRadius: 24,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: props.iconBackground,
          boxShadow: "0px 2px 5px -2px #888",
        }}
      >
        <FontAwesomeIcon
          icon={props.icon}
          color={props.iconColor}
          size={"5x"}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 40,
          flexGrow: 3,
          flexBasis: 0,
          justifyContent: "center",
          minWidth: 280,
        }}
      >
        <div style={{ fontSize: 26, fontWeight: 600 }}>{props.label}</div>
        <div>{props.description}</div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            href="/signup"
            color={getThemeColor("yellow")}
            textColor="initial"
            icon={faFlask}
          >
            Sign Up to Try ➝
          </Button>
        </div>
      </div>
    </div>
  );
}

const Features = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 40,
      }}
      ref={ref}
    >
      <Feature
        number="1"
        label="Counselling"
        description="Choose from over 200 conditions for your practice patient to have. Practice counselling and developing management plans for them."
        icon={faNotesMedical}
        iconColor="hsl(210 73% 95% / 1)"
        iconBackground="hsl(210 73% 75% / 1)"
      />
      <HorizontalDivider />
      <Feature
        number="2"
        label="History of Present Illness"
        description="Practice your focused and relevant history taking with an AI patient who has a random condition."
        icon={faStethoscope}
        iconColor="hsl(210 73% 85% / 1)"
        iconBackground="hsl(210 73% 65% / 1)"
      />
      <HorizontalDivider />
      <Feature
        number="3"
        label="Pediatrics"
        description="Practice with a parent bringing their child in for routine wellness checkups."
        icon={faBabyCarriage}
        iconColor="hsl(210 73% 75% / 1)"
        iconBackground="hsl(210 73% 55% / 1)"
      />
      <HorizontalDivider />
      <Feature
        number="4"
        label="Serious Illness Conversations"
        description="Practice delivering news of a serious illness diagnosis to a patient."
        icon={faSadTear}
        iconColor="hsl(210 73% 65% / 1)"
        iconBackground="hsl(210 73% 45% / 1)"
      />
      <HorizontalDivider />
      <Feature
        number="5"
        label="Natural Conversations"
        description="Use your voice to talk naturally to the practice patient. The patient automatically detects when you've finished speaking and replies."
        icon={faMicrophone}
        iconColor="hsl(210 73% 55% / 1)"
        iconBackground="hsl(210 73% 35% / 1)"
      />
      <HorizontalDivider />
      <Feature
        number="6"
        label="Get Feedback"
        description="After you've finished a session, you can get AI-generated feedback with a summary of what you did well and what you could improve next time."
        icon={faPencil}
        iconColor="hsl(210 73% 45% / 1)"
        iconBackground="hsl(210 73% 25% / 1)"
      />
    </div>
  );
});

const AnimatedCTAs = animated(CTAs);

function Hero(props) {
  const styles = usePopIn({ amount: 5 });

  return (
    <Header
      background={
        "linear-gradient(0deg, " +
        getThemeColor("blue") +
        " 0%, " +
        getThemeColor("lightblue") +
        " 100%)"
      }
      middleContent={
        <div style={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Button
            color="#ffffff1f"
            onClick={() => props.onClick("home")}
            borderRadius="8px 2px 2px 8px"
            icon={faHouse}
          >
            Home
          </Button>
          <Button
            color={"#ffffff1f"}
            onClick={() => props.onClick("pricing")}
            borderRadius="2px"
            icon={faHandHoldingDollar}
          >
            Pricing
          </Button>
          <Button
            color={"#ffffff1f"}
            onClick={() => props.onClick("contact")}
            borderRadius="2px 8px 8px 2px"
            icon={faComment}
          >
            Contact
          </Button>
        </div>
      }
      rightContent={
        <Button color={"#ffffff1f"} href="/signin" icon={faRightToBracket}>
          Sign In
        </Button>
      }
      {...props}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "space-between",
          gap: 40,
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 40,
            flexGrow: 1,
          }}
        >
          <HeroText />

          <AnimatedCTAs style={styles[3]} />
        </div>
        <animated.div
          style={{
            width: 320,
            height: 660,
            position: "relative",
            ...styles[4],
          }}
        >
          <div
            style={{
              width: 6,
              height: 70,
              background: "#444",
              position: "absolute",
              right: -4,
              top: 120,
              borderTopRightRadius: 6,
              borderBottomRightRadius: 6,
            }}
          />
          <div
            style={{
              width: 6,
              height: 30,
              background: "#444",
              position: "absolute",
              left: -4,
              top: 160,
              borderTopLeftRadius: 6,
              borderBottomLeftRadius: 6,
            }}
          />
          <div
            style={{
              width: 6,
              height: 30,
              background: "#444",
              position: "absolute",
              left: -4,
              top: 210,
              borderTopLeftRadius: 6,
              borderBottomLeftRadius: 6,
            }}
          />
          <ChatPreview />
        </animated.div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          fontSize: 14,
          fontWeight: 300,
        }}
      >
        <div></div>
        <div></div>
      </div>
    </Header>
  );
}

function ChatPreview() {
  const [messages, setMessages] = React.useState<Array<string>>([]);
  const [isLoadingMessage, setIsLoadingMessage] =
    React.useState<boolean>(false);

  const messagesToSendOverTime = [
    "Hi John, what brings you in today?",
    "I've been having some rashes on my body, especially on my palms and the soles of my feet.",
    "When did these symptoms start?",
    "They started about 3 weeks ago.",
    "Do you have any pain or itching related to these rashes?",
    "No, there's no pain or itching.",
    "Could you describe what the rashes look like?",
    "They are red, raised, and kind of patchy.",
    "Does the rash look about the same as when it started?",
    "Yes, it looks pretty much the same.",
    "Did you try anything to make the rash go away?",
    "I haven't tried anything yet.",
    "Did you notice anything making the rash worse?",
    "Not that I've noticed. It's been consistent.",
    "Are you having any fever or chills?",
    "No, I haven't had any fever or chills.",
    "What about night sweats or weight loss?",
    "I haven't experienced night sweats, but I have noticed a slight unintentional weight loss.",
  ];

  useAsyncEffect(
    async () => {
      for (let x = 0; x < messagesToSendOverTime.length; x++) {
        setIsLoadingMessage(true);

        await sleep(2500);

        setMessages((old) => {
          const nw = [...old];
          nw.push(messagesToSendOverTime[x]);
          return nw;
        });
        setIsLoadingMessage(false);

        await sleep(1000);
      }
    },
    () => {}
  );

  return (
    <div
      style={{
        height: "100%",
        borderRadius: 32,
        overflow: "hidden",
        color: "#081f35",
        background:
          "linear-gradient(315deg, rgb(248, 251, 252) 0%, rgb(242, 248, 253) 100%)",
        border: "8px solid #444",
        userSelect: "none",
      }}
    >
      <PatientPage
        messages={messages}
        hideHelp
        hidePatientInfo
        isSendingMessage={isLoadingMessage}
      />
    </div>
  );
}

function CTAs(props) {
  return (
    <div
      style={{
        display: "flex",
        gap: 12,
        justifyContent: "center",
        width: "min(360px, 100vw)",
        ...props.style,
      }}
    >
      <Button
        href="/signup"
        color={getThemeColor("yellow")}
        textColor="initial"
        icon={faRightToBracket}
      >
        Sign Up for Free
      </Button>
    </div>
  );
}

const Pricing = React.forwardRef<HTMLDivElement>((_, ref) => {
  const signUpButton = (
    <Button
      href="/signup"
      color={getThemeColor("yellow")}
      textColor="initial"
      icon={faRightToBracket}
    >
      Sign Up
    </Button>
  );

  return (
    <div style={{ width: "100%" }} ref={ref}>
      <SectionHeader>Pricing</SectionHeader>
      <PricingOptions
        config={{
          trial: {
            cta: (
              <Button
                href="/signup"
                color={getThemeColor("yellow")}
                textColor="initial"
                icon={faRightToBracket}
              >
                Sign Up
              </Button>
            ),
          },
          monthly: { cta: signUpButton },
          yearly: { cta: signUpButton },
        }}
      />
    </div>
  );
});

const Contact = React.forwardRef<HTMLDivElement>((_, ref) => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [feedback, setFeedback] = React.useState("");
  const [sendState, setSendState] = React.useState<null | boolean>(null);
  const [error, setError] = React.useState("");

  return (
    <div style={{ width: "100%" }} ref={ref}>
      <SectionHeader>Contact</SectionHeader>
      <div
        style={{
          display: "flex",
          justifyContent: "stretch",
          gap: 40,
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            flexBasis: 0,
            fontSize: 12,
            fontWeight: 300,
            minWidth: 300,
          }}
        >
          It's very important for us to keep in touch with you, please feel free
          to reach out at any time with questions or feedback you may have!
        </div>
        <VertialDivider />
        <div style={{ flexGrow: 1, flexBasis: 0, minWidth: 300 }}>
          <form
            style={{ display: "flex", flexDirection: "column", gap: 8 }}
            onSubmit={async (e) => {
              e.stopPropagation();
              e.preventDefault();

              setError("");
              setSendState(false);
              try {
                await callFirebaseFunction("saveFeedback", {
                  name,
                  email,
                  feedback,
                });
              } catch (e) {
                setSendState(null);
                setError(
                  "Sorry, there was an error sending your feedback, please try again later."
                );
                return;
              }
              setSendState(true);
            }}
          >
            {error && <Banner type="error">{error}</Banner>}
            <input
              required
              style={{
                borderRadius: 8,
                border: "1px solid rgb(214, 235, 253)",
                padding: "12px 16px",
                fontFamily: '"Montserrat", sans-serif',
                fontSize: 14,
              }}
              disabled={sendState != null}
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Your name"
            />
            <input
              required
              style={{
                borderRadius: 8,
                border: "1px solid rgb(214, 235, 253)",
                padding: "12px 16px",
                fontFamily: '"Montserrat", sans-serif',
                fontSize: 14,
              }}
              disabled={sendState != null}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Your email"
            />
            <textarea
              required
              style={{
                borderRadius: 8,
                border: "1px solid rgb(214, 235, 253)",
                padding: "12px 16px",
                fontFamily: '"Montserrat", sans-serif',
                fontSize: 14,
              }}
              disabled={sendState != null}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Your feedback, questions and comments"
            />
            <Button
              color={getThemeColor("yellow")}
              textColor="initial"
              icon={faComment}
              disabled={sendState != null}
              loading={sendState === false}
            >
              {sendState === true ? "Sent!" : "Send Feedback"}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
});

function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();

  return rect.top >= 0 && rect.top <= 200;
}

export function LandingPage(props: { section?: string }) {
  const anchorRefs = React.useRef({});
  const currentFocusedAnchorRef = React.useRef("home");

  React.useEffect(() => {
    const listener = () => {
      const refMap = anchorRefs.current;

      let newCurrentFocusedAnchor: string | null = null;
      for (let key in refMap) {
        if (isElementInViewport(refMap[key])) {
          newCurrentFocusedAnchor = key;
        }
      }

      if (
        newCurrentFocusedAnchor != null &&
        newCurrentFocusedAnchor != currentFocusedAnchorRef.current
      ) {
        log({ event: "scroll", target: newCurrentFocusedAnchor });
      }

      if (newCurrentFocusedAnchor != null) {
        currentFocusedAnchorRef.current = newCurrentFocusedAnchor;
      }
    };

    window.addEventListener("scroll", listener);

    return () => window.removeEventListener("scroll", listener);
  });

  React.useEffect(() => {
    if (props.section != null) {
      const section = anchorRefs.current[props.section];
      if (section != null) {
        section.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [props.section]);

  return (
    <div>
      <div ref={(ele) => (anchorRefs.current["home"] = ele)} />
      <Hero
        onClick={(section) => {
          anchorRefs.current[section].scrollIntoView({
            behavior: "smooth",
          });
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 40,
            width: "min(1100px, 100vw)",
            padding: "60px 20px",
            alignItems: "center",
          }}
        >
          <Features ref={(ele) => (anchorRefs.current["features"] = ele)} />
          <HorizontalDivider />
          <Testimonials
            ref={(ele) => (anchorRefs.current["testimonials"] = ele)}
          />
          <HorizontalDivider />
          <Pricing ref={(ele) => (anchorRefs.current["pricing"] = ele)} />
          <HorizontalDivider />
          <Contact ref={(ele) => (anchorRefs.current["contact"] = ele)} />
        </div>
      </div>
      <PreFooterUpsell />
      <Footer />
    </div>
  );
}
