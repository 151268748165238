export function getThemeColorDeemphasized(iconColor: string): string {
  if (iconColor === "blue") {
    return "#d4e9f7";
  }

  if (iconColor === "lightblue") {
    return "#bedbff";
  }

  if (iconColor === "purple") {
    return "#f3daff";
  }

  if (iconColor === "yellow") {
    return "#fff3cc";
  }

  if (iconColor === "green") {
    return "#d0fbef";
  }

  if (iconColor === "red") {
    return "#fceae8";
  }

  return "#fff";
}
