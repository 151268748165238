import { auth } from "./utils/firebase";
import * as React from "react";
import Header from "./Header";
import { getThemeColor } from "./utils/getThemeColor";
import { Button } from "./Button";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import Banner from "./Banner";
import usePopIn from "./utils/usePopIn";
import { animated } from "@react-spring/web";
import ContentBox from "./ContentBox";
import { HeroText } from "./HeroText";

const READABLE_ERROR_MAP: Record<string, string> = {
  "auth/email-already-in-use":
    "Sorry, that email is already in use by another account. Please use another email.",
  "auth/wrong-password":
    "We were unable to log you in with the provided email and password.",
  "auth/invalid-credential":
    "We were unable to log you in with the provided email and password.",
  "auth/weak-password":
    "Sorry, that password is too easy to guess, please try a more secure password.",
};

export function LoginPage({ isSignUp }: { isSignUp: boolean }) {
  const navigate = useNavigate();

  const styles = usePopIn({ amount: 1 });

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);

  return (
    <>
      <style>
        {`
          .mainform {
            padding: 40px 0 40px 40px;
          }

          .maintext {
            font-size: 60px;
          }

          @media (max-width: 720px) {
            .mainform {
              padding: 0;
            }

            .maintext {
              font-size: 50px;
            }
          }
        `}
      </style>
      <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <Header padding={"8px 20px"} background={getThemeColor("lightblue")} />
        <animated.div
          style={{
            display: "flex",
            width: "100%",
            padding: 20,
            alignItems: "center",
            justifyContent: "center",
            ...styles[0],
          }}
        >
          <ContentBox
            style={{
              display: "flex",
              padding: 20,
              gap: 40,
              width: "min(1100px, calc(100vw - 40px))",
              flexWrap: "wrap",
            }}
          >
            <div
              className="mainform"
              style={{
                flexGrow: 1,
                flexBasis: 0,
                display: "flex",
                flexDirection: "column",
                gap: 40,
                minWidth: "min(100vw - 80px, 480px)",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                <div style={{ fontSize: 32, fontWeight: 600 }}>
                  {isSignUp ? "Sign up" : "Log in"}
                </div>
                <div style={{ fontSize: 14, fontWeight: 300 }}>
                  {isSignUp ? "Sign up" : "Log in"} with email or use social
                  login
                </div>
              </div>
              <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
                <div
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    icon={faGoogle}
                    onClick={async () => {
                      setError(null);
                      setIsLoading(true);
                      try {
                        await signInWithPopup(auth, new GoogleAuthProvider());
                      } catch (e) {
                        const readableError = READABLE_ERROR_MAP[e.code];
                        if (readableError != null) {
                          setError(readableError);
                        } else {
                          setError(
                            "An unknown error occurred. Please try again later (" +
                              e.code +
                              ")"
                          );
                        }
                        setIsLoading(false);
                        return;
                      }
                      setIsLoading(false);
                      navigate("/");
                      if (isSignUp) {
                        fbq("track", "CompleteRegistration");
                        gtag("event", "conversion", {
                          send_to: "AW-11481660388/0vFjCKK20YsZEOT38OIq",
                        });
                      }
                    }}
                  >
                    {isSignUp ? "Sign up" : "Log in"} with Google
                  </Button>
                </div>
              </div>
              <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                <div style={{ flexGrow: 1, height: 1, background: "#eee" }} />
                <div>or</div>
                <div style={{ flexGrow: 1, height: 1, background: "#eee" }} />
              </div>
              {error && <Banner type="error">{error}</Banner>}
              {success && <Banner type="success">{success}</Banner>}
              <form
                style={{ display: "flex", flexDirection: "column", gap: 20 }}
                onSubmit={async (e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  setIsLoading(true);
                  setError(null);

                  try {
                    if (isSignUp) {
                      const credential = await createUserWithEmailAndPassword(
                        auth,
                        email,
                        password
                      );

                      await updateProfile(credential.user, {
                        displayName: name,
                      });

                      await sendEmailVerification(credential.user, {
                        url: "https://compatient.ca",
                      });

                      fbq("track", "CompleteRegistration");
                      gtag("event", "conversion", {
                        send_to: "AW-11481660388/0vFjCKK20YsZEOT38OIq",
                      });
                    } else {
                      // Log in
                      await signInWithEmailAndPassword(auth, email, password);
                    }
                  } catch (e) {
                    const readableError = READABLE_ERROR_MAP[e.code];
                    if (readableError != null) {
                      setError(readableError);
                    } else {
                      setError(
                        "An unknown error occurred. Please try again later (" +
                          e.code +
                          ")"
                      );
                    }
                    setIsLoading(false);
                    return;
                  }

                  setIsLoading(false);
                  navigate("/");
                }}
              >
                {isSignUp && (
                  <input
                    required
                    style={{
                      borderRadius: 8,
                      border: "1px solid #ddd",
                      padding: "12px 16px",
                      fontFamily: '"Montserrat", sans-serif',
                      fontSize: 14,
                    }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Enter your name"
                  />
                )}
                <input
                  required
                  style={{
                    borderRadius: 8,
                    border: "1px solid #ddd",
                    padding: "12px 16px",
                    fontFamily: '"Montserrat", sans-serif',
                    fontSize: 14,
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Enter your email"
                />
                <input
                  required
                  style={{
                    borderRadius: 8,
                    border: "1px solid #ddd",
                    padding: "12px 16px",
                    fontFamily: '"Montserrat", sans-serif',
                    fontSize: 14,
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="Enter your password"
                />
                {isSignUp && (
                  <div style={{ fontSize: 14, fontWeight: 300 }}>
                    By clicking Sign up, Sign up with Google, or Sign up with
                    Facebook, you agree to our{" "}
                    <Link to="/terms">Terms of Service</Link> and{" "}
                    <Link to="/privacy">Privacy Policy</Link>.
                  </div>
                )}
                {!isSignUp && (
                  <a
                    style={{ fontSize: 14, fontWeight: 300, cursor: "pointer" }}
                    onClick={async () => {
                      if (email.trim() === "") {
                        setError(
                          "You must enter an email to reset your password"
                        );
                        return;
                      }

                      setSuccess(null);
                      setError(null);
                      setIsLoading(true);
                      await sendPasswordResetEmail(auth, email);
                      setIsLoading(false);
                      setSuccess(
                        "An email with a link to reset your password has been sent to the provided email address"
                      );
                    }}
                  >
                    Forgot password?
                  </a>
                )}
                <Button
                  color={getThemeColor("yellow")}
                  textColor="#081f35"
                  icon={faRightToBracket}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  {isSignUp ? "Sign up" : "Log in"}
                </Button>
              </form>
              <div style={{ fontSize: 14 }}>
                {isSignUp ? "Have" : "Need"} an account?{" "}
                <Link to={isSignUp ? "/signin" : "/signup"}>
                  {isSignUp ? "Log in" : "Sign up"}
                </Link>
              </div>
            </div>
            <div
              style={{
                flexGrow: 1,
                flexBasis: 0,
                padding: 20,
                background:
                  "linear-gradient(0deg, " +
                  getThemeColor("blue") +
                  " 0%, " +
                  getThemeColor("lightblue") +
                  " 100%)",
                borderRadius: 8,
                color: "#eee",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 20,
                overflow: "hidden",
                minWidth: "min(100vw - 80px, 480px)",
              }}
            >
              <HeroText />
            </div>
          </ContentBox>
        </animated.div>
      </div>
    </>
  );
}
