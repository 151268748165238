import * as React from "react";
import { getThemeColor } from "./utils/getThemeColor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSpinner from "./LoadingSpinner";
import { useSpring, animated } from "@react-spring/web";
import lightenDarkenColor from "./utils/lightenDarkenColor";
import useMouseEvents from "./utils/useMouseEvents";
import { Link } from "react-router-dom";

const AnimatedLink = animated(Link);

export function Button({
  width,
  textColor,
  color,
  children,
  onClick,
  href,
  disabled,
  loading,
  icon,
  borderRadius,
}: {
  width?: number | string;
  textColor?: string;
  color?: string;
  children?: React.ReactNode;
  href?: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  icon?: any;
  borderRadius?: string;
}) {
  const iconEle = icon != null ? <FontAwesomeIcon icon={icon} /> : null;

  const { props, hover, active } = useMouseEvents();

  const defaultBackground = color ?? getThemeColor("lightblue");

  const spring = disabled
    ? {
        background: lightenDarkenColor(defaultBackground, -40),
      }
    : active
    ? {
        background: lightenDarkenColor(defaultBackground, -20),
      }
    : hover
    ? {
        background: lightenDarkenColor(defaultBackground, 20),
      }
    : {
        background: defaultBackground,
      };
  const springStyle = useSpring({
    ...spring,
    config: {
      mass: 5,
      friction: 80,
      tension: 800,
    },
  });

  const commonProps: any = {
    onClick,
    style: {
      border: "none",
      color: textColor ?? "#eee",
      padding: "12px 16px",
      borderRadius: borderRadius ?? 8,
      cursor: "pointer",
      fontSize: 14,
      width,
      boxShadow: "0px 2px 5px -2px rgba(0, 0, 0, 0.2)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 8,
      userSelect: "none",
      ...springStyle,
    },
    ...props,
  };

  if (href != null) {
    return (
      <AnimatedLink draggable={false} to={href} {...commonProps}>
        {iconEle}
        {children}
        {loading && (
          <LoadingSpinner color="rgba(255, 255, 255, 0.8)" size={16} />
        )}
      </AnimatedLink>
    );
  }

  return (
    <animated.button disabled={disabled} {...commonProps}>
      {iconEle}
      {children}
      {loading && <LoadingSpinner color="rgba(255, 255, 255, 0.8)" size={16} />}
    </animated.button>
  );
}
