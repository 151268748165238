function semiUuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function maybeSetLocalStorage(key: string, item: string | null): void {
  if (item != null) {
    localStorage.setItem(key, item);
  }
}

function useLocalStorage(key: string, dflt: string | null): string | null {
  const item = localStorage.getItem(key) ?? dflt;
  maybeSetLocalStorage(key, item);

  return item;
}

export function resetIdentify() {
  localStorage.removeItem("_uid");
  localStorage.removeItem("_email");
  localStorage.removeItem("_name");
}

export function identify(data: {
  uid: string;
  email: string | null;
  name: string | null;
}) {
  maybeSetLocalStorage("_uid", data.uid);
  maybeSetLocalStorage("_email", data.email);
  maybeSetLocalStorage("_name", data.name);

  log({ event: "auth", target: "set" });
}

export function log(data: {
  event: "click" | "pageview" | "scroll" | "auth";
  target: string;
}) {
  const uuid = useLocalStorage("_uuid", semiUuid());
  const uid = useLocalStorage("_uid", null);
  const email = useLocalStorage("_email", null);
  const name = useLocalStorage("_name", null);

  const fullData = {
    ...data,
    url: window.location.href,
    time: Date.now() / 1000,
    useragent: window.navigator.userAgent,
    uuid,
    uid,
    email,
    name,
    referrer: document.referrer,
  };
  const params = new URLSearchParams();
  params.set("table", "osce");
  params.set("data", JSON.stringify(fullData));

  fetch("https://log-dzn25yxrjq-uc.a.run.app?" + params.toString());
}
