import * as React from "react";
import conditions from "../../functions/conditions.json";
import {
  faBolt,
  faChild,
  faFaceSadTear,
  faNotesMedical,
  faStethoscope,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { Button } from "./Button";
import useAsyncEffect from "./utils/useAsyncEffect";
import { getYearMonthDay } from "./utils/getYearMonthDay";
import { useNavigate } from "react-router";
import callFirebaseFunction from "./utils/callFirebaseFunction";
import { FullPageLoadingSpinner } from "./FullPageLoadingSpinner";
import { auth } from "./utils/firebase";
import { sendEmailVerification } from "firebase/auth";
import Banner from "./Banner";
import { animated } from "@react-spring/web";
import usePopIn from "./utils/usePopIn";
import { Link } from "react-router-dom";
import ContentBox from "./ContentBox";
import { IconWithBackground } from "./IconWithBackground";

function OverallConditionProgress({ numRandomConditions, shuffleData }) {
  if (shuffleData == null) {
    return <div>Loading completion...</div>;
  }

  const overallNumCompleted = shuffleData.index;

  return (
    <div>
      <strong>
        {overallNumCompleted} / {numRandomConditions}
      </strong>{" "}
      conditions completed (
      <strong>
        {Math.round((overallNumCompleted / numRandomConditions) * 100)}%
      </strong>
      )
    </div>
  );
}

function ChoiceSection({ label, children, icon, iconColor, style }) {
  return (
    <ContentBox
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        padding: 16,
        justifyContent: "space-between",
        ...style,
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: 8,
          alignItems: "center",
        }}
      >
        <IconWithBackground icon={icon} color={iconColor} iconSize="lg" />
        <h3 style={{ margin: 0 }}>{label}</h3>
      </div>
      {children}
    </ContentBox>
  );
}

const AnimatedChoiceSection = animated(ChoiceSection);

export default function CreatePatient() {
  const navigate = useNavigate();
  const [shuffleData, setShuffleData] = React.useState<null | {
    numConditions?: number;
    index?: number;
    error?: boolean;
  }>(null);
  const [error, setError] = React.useState<null | React.ReactNode>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [condition, setCondition] = React.useState("");
  const [verificationState, setVerificationState] = React.useState<
    null | boolean
  >(null);
  const [pediatricsOption, setPediatricsOption] = React.useState<{
    ageDays: number;
    genderIdx: number;
  } | null>(null);

  useAsyncEffect(
    async () => {
      try {
        return await callFirebaseFunction<{
          numConditions: number;
          index: number;
        }>("getShuffle", {});
      } catch {
        return { error: true };
      }
    },
    (result) => setShuffleData(result)
  );

  const styles = usePopIn({ amount: 5 });

  if (isLoading) {
    return <FullPageLoadingSpinner label="Creating new patient" />;
  }

  const user = auth.currentUser;

  const banner = user != null && user.emailVerified === false && (
    <Banner type="error">
      <div>
        You must first verify your email before you can create patients.{" "}
        <a
          style={{ cursor: "pointer" }}
          onClick={async () => {
            setVerificationState(false);
            await sendEmailVerification(user, {
              url: "https://compatient.ca",
            });
            setVerificationState(true);
          }}
        >
          {verificationState === true
            ? "Sent!"
            : verificationState === false
            ? "Sending..."
            : "Send verification email."}
        </a>
      </div>
    </Banner>
  );

  const conditionDropdown = (
    <Select
      styles={{
        control: (base) => ({
          ...base,
          borderRadius: 8,
        }),
        container: (base) => ({
          ...base,
          display: "inline-block",
          minWidth: 240,
        }),
      }}
      options={conditions.conditions
        .filter((condition) => condition.isDx !== true)
        .map((condition) => ({
          value: condition.name,
          label: condition.name,
        }))}
      onChange={(val) => val != null && setCondition(val.value)}
    />
  );

  const pediatricsAgeDays = [
    3,
    5,
    1 * 30,
    2 * 30,
    4 * 30,
    6 * 30,
    9 * 30,
    12 * 30,
    15 * 30,
    18 * 30,
    24 * 30,
    30 * 30,
    3 * 12 * 30,
    4 * 12 * 30,
    5 * 12 * 30,
    6 * 12 * 30,
    7 * 12 * 30,
    8 * 12 * 30,
    9 * 12 * 30,
    10 * 12 * 30,
    11 * 12 * 30,
    12 * 12 * 30,
    13 * 12 * 30,
    14 * 12 * 30,
    15 * 12 * 30,
    16 * 12 * 30,
    17 * 12 * 30,
    18 * 12 * 30,
    19 * 12 * 30,
    20 * 12 * 30,
    21 * 12 * 30,
  ];
  const genderIdxs = [0, 1];

  const pediatricOptions = pediatricsAgeDays.flatMap((ageDays) => {
    return genderIdxs.map((genderIdx) => {
      return { ageDays, genderIdx };
    });
  });

  const pediatricsAgeDropdown = (
    <Select
      styles={{
        control: (base) => ({
          ...base,
          borderRadius: 8,
        }),
        container: (base) => ({
          ...base,
          display: "inline-block",
          minWidth: 240,
        }),
      }}
      options={pediatricOptions.map((option) => {
        const yearMonthDay = getYearMonthDay(option.ageDays);

        return {
          value: option,
          label: yearMonthDay + " old " + ["boy", "girl"][option.genderIdx],
        };
      })}
      onChange={(val) => val != null && setPediatricsOption(val.value)}
    />
  );

  const numRandomConditions = conditions.conditions.filter(
    (conditionData) => !conditionData.isTopic
  ).length;

  const createPatient = async (args: {
    type: string;
    condition?: string;
    ageDays?: number | undefined;
    genderIdx?: number | undefined;
  }) => {
    setIsLoading(true);

    try {
      const parsedResult = await callFirebaseFunction<{ id: string }>(
        "start",
        args
      );
      navigate("/patient/" + parsedResult.id);
    } catch (e) {
      if (
        e?.details?.error === "rate-limit" ||
        e?.details?.error === "trial-counselling-only"
      ) {
        setError(
          <div>
            {e.message} <Link to="/settings">Upgrade plan</Link>
          </div>
        );
      } else {
        setError(
          "Sorry, an unexpected error occurred (" +
            e.message +
            "). Please try again later."
        );
      }
    }
    setIsLoading(false);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 20,
          padding: 20,
          flexDirection: "column",
          width: "100vw",
          maxWidth: 1200,
          alignItems: "center",
        }}
      >
        <animated.h1 style={styles[0]}>Create Patient</animated.h1>
        {banner}
        {error && <Banner type="error">{error}</Banner>}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            flexWrap: "wrap",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <AnimatedChoiceSection
            label="History of Present Illness"
            icon={faStethoscope}
            iconColor="green"
            style={styles[1]}
          >
            <div>
              Create a patient who is seeking medical care for a randomly
              selected condition. You’ll need to spend 5 to 10 minutes obtaining
              a focused and relevant history from them. At the end, you’ll be
              able to learn what condition they had, and get a rating for your
              session.
            </div>
            <OverallConditionProgress
              shuffleData={shuffleData}
              numRandomConditions={numRandomConditions}
            />
            <Button
              disabled={isLoading || !user?.emailVerified}
              onClick={() => {
                createPatient({
                  type: "diagnosis",
                });
              }}
              icon={faBolt}
            >
              Create Patient
            </Button>
          </AnimatedChoiceSection>
          <AnimatedChoiceSection
            label="Counselling"
            icon={faNotesMedical}
            iconColor="blue"
            style={styles[2]}
          >
            <div>
              Create a patient who has {conditionDropdown}. You’ll need to spend
              5 to 10 minutes counselling and developing a management plan for
              their {condition != "" ? condition : "condition"}.
            </div>
            <Button
              disabled={isLoading || condition === "" || !user?.emailVerified}
              onClick={() => {
                createPatient({
                  type: "counselling",
                  condition,
                });
              }}
              icon={faBolt}
            >
              Create Patient
            </Button>
          </AnimatedChoiceSection>
          <AnimatedChoiceSection
            label="Pediatrics"
            icon={faChild}
            iconColor="red"
            style={styles[3]}
          >
            <div>
              Create a patient who has a {pediatricsAgeDropdown}, who is
              bringing their child in for a routine wellness visit.
            </div>
            <Button
              disabled={
                isLoading || pediatricsOption == null || !user?.emailVerified
              }
              onClick={() => {
                createPatient({
                  type: "pediatrics",
                  ageDays: pediatricsOption?.ageDays,
                  genderIdx: pediatricsOption?.genderIdx,
                });
              }}
              icon={faBolt}
            >
              Create Patient
            </Button>
          </AnimatedChoiceSection>
          <AnimatedChoiceSection
            label="Serious Illness Conversations"
            icon={faFaceSadTear}
            iconColor="purple"
            style={styles[4]}
          >
            <div>
              Create a patient who you will deliver news of a serious illness
              diangnosis to. Practice your bedside manner and delivery for these
              difficult conversations. Receive ideas for improvement from the AI
              after the conversation.
            </div>
            <Button
              disabled={isLoading || !user?.emailVerified}
              onClick={() => {
                createPatient({
                  type: "difficult",
                });
              }}
              icon={faBolt}
            >
              Create Patient
            </Button>
          </AnimatedChoiceSection>
        </div>
      </div>
    </div>
  );
}
