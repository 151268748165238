import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleXmark,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { getThemeColor } from "./utils/getThemeColor";
import { Tooltip } from "react-tooltip";

export function PricingFeature(props) {
  return (
    <>
      <div
        style={{
          marginLeft: 8,
          display: "flex",
          gap: 8,
          alignItems: "center",
          fontSize: 15,
        }}
      >
        <FontAwesomeIcon
          icon={props.disabled ? faCircleXmark : faCheckCircle}
          color={props.disabled ? "#666" : getThemeColor("green")}
        />
        {props.children}
        <FontAwesomeIcon
          data-tooltip-id="pricingtooltip"
          data-tooltip-content={props.tooltip}
          icon={faInfoCircle}
          color="#081f35"
          size={"xs"}
        />
      </div>
      <Tooltip
        id="pricingtooltip"
        globalCloseEvents={{
          clickOutsideAnchor: true,
        }}
        style={{
          maxWidth: "min(calc(100% - 40px), 320px)",
          background: "#fafafa",
          color: "inherit",
          boxShadow: "0px 0px 5px -2px #eee",
          borderRadius: 12,
        }}
      />
    </>
  );
}
